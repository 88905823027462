div.mat-mdc-menu-panel {
  box-shadow: none;
  border: 1px solid #e1e1e6;
  border-radius: 0;
  min-height: 34px;

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }

  .mat-mdc-menu-item {
    height: 34px;
    display: flex;
    align-items: center;
    font-weight: 300;
    min-height: 0 !important;
  }
}
