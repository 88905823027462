.mat-mdc-button-base {
  &.mat-mdc-unelevated-button,
  &.mat-mdc-raised-button,
  &.mat-mdc-button {
    border-radius: 0;
    line-height: 24px;
    height: 44px;
    font-weight: 700;
  }

  &.mat-mdc-icon-button {
    &.mat-accent {
      height: 44px;
      width: 48px;
      border-radius: 0;
      color: var(--color-primary);
      background-color: var(--color-accent);
    }

    & svg {
      padding: 2px;
    }
  }
}

.mat-mdc-button {
  &.button--link {
    font-size: 12px;
    font-weight: 400;
    &:not(.mat-mdc-button-disabled) {
      color: var(--color-blue);
    }
  }
}

app-export-button {
  & .mat-mdc-button-base.mat-mdc-icon-button.mat-accent {
    height: auto;
  }

  & span {
    font-weight: 600;
  }

  & .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0 14px;
  }

  & button {
    line-height: 40px !important;
  }
}

.mdc-button {
  min-width: 0 !important;
}

.filter__action .mdc-button {
  padding: 0 16px;
}

.buttons-container {
  justify-content: left;
  border: none;
  display: flex;
  margin-top: 4vh;
  gap: 15px;
}

.button-container {
  float: right;
}
