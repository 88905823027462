@import '../../../styles.scss';

.dcms-mat-display-4 {
  @extend .mat-display-4 !optional;
  margin-bottom: 0;
}

.dcms-mat-display-3 {
  @extend .mat-display-3 !optional;
  margin-bottom: 0;
}

.dcms-mat-display-2 {
  @extend .mat-display-2 !optional;
  margin-bottom: 0;
}

.dcms-mat-display-1 {
  @extend .mat-display-1 !optional;
  margin-bottom: 0;
}

.dcms-mat-option-disabled {
  opacity: 0.2;
}

.dcms-mat-caption {
  @extend .mat-caption !optional;
}

.dcms-mat-button {
  @extend .mat-button !optional;
}

h2 {
  @extend .dcms-mat-display-4;
}

h3 {
  @extend .dcms-mat-display-3;
}

h4 {
  @extend .dcms-mat-display-2;
}

h5 {
  @extend .dcms-mat-display-1;
  margin-bottom: 30px;
}

h6 {
  @extend .mat-headline !optional;
  margin-bottom: 0;
}

p,
span {
  font-size: 1rem;
}

.mat-display-4,
.dcms-mat-display-4,
h2,
.mat-typography .mat-display-4 {
  letter-spacing: normal;
  line-height: 2.857rem;
  @include font-properties(2.285rem, var(--color-primary-600), $ey-font-family, 700);
}

.mat-display-2,
.dcms-mat-display-2,
h4,
.mat-typography .mat-display-2 {
  letter-spacing: normal;
  line-height: 2rem;
  @include font-properties(1.714rem, var(--color-primary-600), $ey-font-family, 700);
}

.mat-display-1,
.dcms-mat-display-1,
h5,
.mat-typography .mat-display-1 {
  letter-spacing: normal;
  line-height: 1.714rem;
  @include font-properties(1.428rem, var(--color-primary-600), $ey-font-family, 700);
}

.mat-h1,
.mat-headline,
h6,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  line-height: 1.28rem;
  @include font-properties(1.142rem, var(--color-primary-600), $ey-font-family, 700);
}
