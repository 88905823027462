.mat-mdc-option {
  min-height: 42px;
  padding: 0 16px;

  & .mdc-list-item__primary-text {
    font-weight: 300;
    font-size: 14px;
    margin: 0 !important;
    width: 100%;
    text-wrap: nowrap !important;
    line-height: 42px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: inline-block;
  }

  & .mat-pseudo-checkbox-minimal {
    display: none;
  }

  &:focus.mdc-list-item {
    background-color: $primary-white;
  }

  &.mat-mdc-option-active.mdc-list-item {
    background-color: var(--mat-option-focus-state-layer-color);
  }

  & .mat-icon,
  & .mat-pseudo-checkbox-full {
    margin-right: 0;
  }

  &.mdc-list-item--disabled {
    opacity: 0.5;
  }

  &.mdc-list-item--disabled .mat-mdc-option-pseudo-checkbox,
  &.mdc-list-item--disabled .mdc-list-item__primary-text,
  &.mdc-list-item--disabled > mat-icon {
    opacity: 1 !important;
  }
}

mat-option.mat-select-hide-options {
  display: none;
}

.mat-mdc-option.contains-mat-select-search mat-pseudo-checkbox {
  display: none;
}
