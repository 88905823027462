.k-button {
  font-size: 14px;
}

.k-button-solid-base:hover,
.k-button-solid-base.k-hover {
  background-color: var(--color-primary-50);
}

.k-button-solid-primary:active,
.k-button-solid-primary.k-active {
  border-color: #ffe600;
  background-color: #ffe600;
}

.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
  box-shadow: unset;
}

.k-list-item:hover,
.k-list-optionlabel:hover,
.k-list-item.k-hover,
.k-hover.k-list-optionlabel {
  background-color: #0000000a;
}

.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  background-color: #0000000a;
}

.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
  background-color: #0000000a;
}

.k-list-content {
  @include dcms-scroll();
}

.k-button-flat-primary {
  color: var(--mdc-list-list-item-label-text-color);
  font-weight: 600;
}

.k-calendar .k-calendar-td:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
  background-color: var(--color-primary-300);
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
  background-color: #2e2e3833;
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
  background-color: $primary-black;
  color: $primary-white;
}

.k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
  background-color: $primary-black;
  color: $primary-white;
}

.k-calendar .k-calendar-view .k-today {
  color: unset;
}

.k-option-inactive {
  opacity: 0.5;
}
