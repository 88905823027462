.mat-step-header {
  border: 1px solid var(--color-primary-300);

  &.mat-horizontal-stepper-header {
    height: 42px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .mat-step-label {
    color: var(--color-primary-300);
  }

  &[aria-selected='true'] {
    background-color: var(--color-primary-900);

    &:hover {
      background-color: var(--color-primary-900);
    }

    .mat-step-label {
      &.mat-step-label-active {
        color: #fff;
      }
    }

    .mat-step-icon-state-edit {
      background: var(--color-primary-900);
      border: 1px solid #fff;
    }

    &:not([aria-labelledby='dcms-mat-stepper__step--completed']) {
      .mat-icon {
        display: none;
      }
    }
  }

  &[aria-labelledby='dcms-mat-stepper__step--completed'] {
    background-color: var(--color-accent);
    border: 1px solid var(--color-accent);

    &:hover {
      background-color: var(--color-accent);
      border: 1px solid var(--color-accent);
    }
  }

  .mat-step-icon-content {
    > span {
      display: none;
    }
  }

  .mat-step-icon-state-number {
    background-color: transparent;
    border: 1px solid var(--color-primary-300);
  }
}

.mat-horizontal-stepper-header {
  height: 42px;
  padding-left: 15px;
  padding-right: 15px;
}

div.mat-stepper-horizontal-line {
  margin: 0;
  border-top-width: 2px;
  border-top-color: var(--color-primary-50);
}
