@import '../shared/variables.scss';
@import '../shared/mixins.scss';

.mat-mdc-tab-nav-bar {
  &.mat-primary {
    .mat-ink-bar {
      background-color: $primary-yellow;
    }
  }
}

.mdc-tab {
  min-width: 160px !important;
}

.mat-mdc-tab-group {
  &.mat-primary {
    height: 100% !important;

    .mat-mdc-tab-header {
      border-bottom: 1px solid $tab-header-border;

      .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {
            .mdc-tab-indicator {
              &--active {
                background: $primary-yellow;
                opacity: 1;
              }
            }
          }
          .mat-ink-bar {
            background-color: $primary-yellow;
          }
        }
      }
    }
    .mat-mdc-tab-body-wrapper {
      .mat-mdc-tab-body {
        & .mat-mdc-icon-button img {
          padding: 2px;
        }

        &.mat-mdc-tab-body-active {
          overflow-y: auto;
          overflow-x: hidden;
        }
        .mat-mdc-tab-body-content {
          //TODO: Adjust on dedicated screens
          //padding: 70px 70px 0 70px;
          @include dcms-scroll();
        }
      }
    }
  }

  &.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 0 !important;
  }
}

.mat-mdc-tab {
  &.mdc-tab--active:focus {
    & .mdc-tab-indicator__content--underline {
      background: $primary-yellow !important;
      border-color: $primary-yellow !important;
    }
  }

  & .mdc-tab-indicator {
    &__content {
      transition: 500ms cubic-bezier(0.35, 0, 0.25, 1) !important;

      &--underline {
        border-color: $primary-yellow !important;
      }
    }
  }
}

::ng-deep .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0;
}

.form-group {
  padding-left: 0em !important;
}
