.dcms-side-dialog.modal-dialog {
  --bs-modal-width: 772px;

  .modal-body {
    max-height: 100%;
    overflow: auto;
    display: flex;
    padding: 0;
    flex-direction: column;
    @include dcms-scroll();

    & .material-icons {
      font-size: 21px;
    }

    & .mat-icon {
      height: 21px;
      width: 21px;
    }

    & .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 28px;
      width: var(--mdc-icon-button-state-layer-size);
      height: var(--mdc-icon-button-state-layer-size);
      padding: 0;
    }
  }

  .controls-container {
    padding-top: 60px;
    height: unset;
  }

  .controls-container,
  .buttons-container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .buttons-container {
    margin-top: auto;
    flex-shrink: 0;
    padding-bottom: 60px;
    padding-top: 30px;
  }

  .modal-content {
    &:has(> .buttons-container) {
      .modal-body {
        padding-bottom: 0;
      }
    }
  }

  .modal-footer {
    @include display-flex(row, center, left);
    border: none;
    flex-shrink: 0;
    gap: 15px;
    margin-top: auto;
    padding: 2rem 5rem 4rem 5rem;
  }
}

.modal-static.modal {
  > .dcms-side-dialog {
    transform: none;
  }
}
