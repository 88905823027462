.dcms-search-list-dialog.modal-dialog {
  --bs-modal-width: 712px;

  .modal-body {
    max-height: 100%;
    overflow: auto;
    padding: 0;
    @include display-flex(column, flex-start, flex-start);
  }

  .controls-container {
    padding-top: 2rem;
    height: unset;
  }

  .controls-container,
  .buttons-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .buttons-container {
    margin-top: auto;
    flex-shrink: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .modal-content {
    &:has(> .buttons-container) {
      .modal-body {
        padding-bottom: 0;
      }
    }
  }

  .modal-footer {
    border: none;
    @include display-flex(row, center, left);
    flex-shrink: 0;
    gap: 15px;
    margin-top: auto;
    padding: 2rem 2rem 2rem 2rem;
  }
}
