.mat-icon {
  cursor: pointer;
}

.bi-caret-down-fill::before {
  font-size: 14px;
}

.mat-mdc-button > .mat-icon,
.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
  @include icon-size(15px);
}

.mat-mdc-unelevated-button > .mat-icon {
  margin-top: 2px;
}

.overview-tile-icons {
  font-size: 1em;
  vertical-align: middle;
}

.active-icon {
  margin-left: 0.8214rem;
}

.search-icon {
  cursor: pointer;
  float: right;
  margin-top: -2rem;
  margin-right: 0.625rem;
}
