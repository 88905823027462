.mat-mdc-checkbox {
  .mdc-checkbox__background {
    border-radius: 0 !important;
    width: 16px !important;
    height: 16px !important;
    border-width: 1px !important;
    border-color: $text-black !important;
  }

  .mdc-checkbox__mixedmark {
    width: calc(100% - 6px);
  }
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: scale(0.8);
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2e2e38;
  --mdc-checkbox-selected-hover-icon-color: #2e2e38;
  --mdc-checkbox-selected-icon-color: #2e2e38;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2e2e38;
  --mdc-checkbox-selected-hover-state-layer-color: #2e2e38;
  --mdc-checkbox-selected-pressed-state-layer-color: #2e2e38;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
