/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
html {
  --mat-badge-text-font: EYInterstate;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 700 1.142rem / 1.28rem EYInterstate;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px EYInterstate;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px EYInterstate;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 15px / 24px EYInterstate;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px EYInterstate;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px EYInterstate;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 24px EYInterstate;
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px EYInterstate;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.toast-container__toast-title,
.dcms-mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: lighter 0.857rem / 1.142rem EYInterstate;
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 700 2.285rem / 2.857rem EYInterstate;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 700 2rem / 2.285rem EYInterstate;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 700 1.714rem / 2rem EYInterstate;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 700 1.428rem / 1.714rem EYInterstate;
  letter-spacing: normal;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: EYInterstate;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: EYInterstate;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 15px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: EYInterstate;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 15px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: EYInterstate;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 1rem;
  --mat-datepicker-calendar-body-label-text-weight: 700;
  --mat-datepicker-calendar-period-button-text-size: 1rem;
  --mat-datepicker-calendar-period-button-text-weight: 700;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: EYInterstate;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: EYInterstate;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 0.857rem;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 0.857rem;
}

html {
  --mat-stepper-container-text-font: EYInterstate;
  --mat-stepper-header-label-text-font: EYInterstate;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 15px;
  --mat-stepper-header-selected-state-label-text-size: 15px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: EYInterstate;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: EYInterstate;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: EYInterstate;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 15px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: EYInterstate;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 15px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: EYInterstate;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: EYInterstate;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: EYInterstate;
  --mdc-plain-tooltip-supporting-text-size: 0.857rem;
  --mdc-plain-tooltip-supporting-text-weight: lighter;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-label-text-font: EYInterstate;
  --mdc-filled-text-field-label-text-size: 15px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: EYInterstate;
  --mdc-outlined-text-field-label-text-size: 15px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: EYInterstate;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 15px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 15px;
  --mat-form-field-subscript-text-font: EYInterstate;
  --mat-form-field-subscript-text-line-height: 1.142rem;
  --mat-form-field-subscript-text-size: 0.857rem;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: lighter;
}

html {
  --mat-select-trigger-text-font: EYInterstate;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 15px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: EYInterstate;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: EYInterstate;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 15px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: EYInterstate;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: EYInterstate;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: normal;
  --mat-switch-label-text-weight: 400;
}

html {
  --mat-radio-label-text-font: EYInterstate;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: normal;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: EYInterstate;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: EYInterstate;
  --mat-menu-item-label-text-size: 15px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: EYInterstate;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 15px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: EYInterstate;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: EYInterstate;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1.142rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.857rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: lighter;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px EYInterstate;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-font: EYInterstate;
  --mat-paginator-container-text-line-height: 1.142rem;
  --mat-paginator-container-text-size: 0.857rem;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: lighter;
  --mat-paginator-select-trigger-text-size: 0.857rem;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: EYInterstate;
  --mat-tab-header-label-text-size: 1rem;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 1rem;
  --mat-tab-header-label-text-weight: 700;
}

html {
  --mat-checkbox-label-text-font: EYInterstate;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: normal;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: EYInterstate;
  --mdc-text-button-label-text-size: 1rem;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 700;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: EYInterstate;
  --mdc-filled-button-label-text-size: 1rem;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 700;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: EYInterstate;
  --mdc-protected-button-label-text-size: 1rem;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 700;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: EYInterstate;
  --mdc-outlined-button-label-text-size: 1rem;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 700;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: EYInterstate;
  --mdc-extended-fab-label-text-size: 1rem;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 700;
}

html {
  --mdc-snackbar-supporting-text-font: EYInterstate;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: EYInterstate;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: EYInterstate;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: EYInterstate;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mat-badge-text-font: EYInterstate;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 700 1.142rem / 1.28rem EYInterstate;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px EYInterstate;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px EYInterstate;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 15px / 24px EYInterstate;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px EYInterstate;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px EYInterstate;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 24px EYInterstate;
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px EYInterstate;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.toast-container__toast-title,
.dcms-mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: lighter 0.857rem / 1.142rem EYInterstate;
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 700 2.285rem / 2.857rem EYInterstate;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 700 2rem / 2.285rem EYInterstate;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 700 1.714rem / 2rem EYInterstate;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 700 1.428rem / 1.714rem EYInterstate;
  letter-spacing: normal;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: EYInterstate;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: EYInterstate;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 15px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: EYInterstate;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 15px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: EYInterstate;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 1rem;
  --mat-datepicker-calendar-body-label-text-weight: 700;
  --mat-datepicker-calendar-period-button-text-size: 1rem;
  --mat-datepicker-calendar-period-button-text-weight: 700;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: EYInterstate;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: EYInterstate;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 0.857rem;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 0.857rem;
}

html {
  --mat-stepper-container-text-font: EYInterstate;
  --mat-stepper-header-label-text-font: EYInterstate;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 15px;
  --mat-stepper-header-selected-state-label-text-size: 15px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: EYInterstate;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: EYInterstate;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: EYInterstate;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 15px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: EYInterstate;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 15px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: EYInterstate;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: EYInterstate;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: EYInterstate;
  --mdc-plain-tooltip-supporting-text-size: 0.857rem;
  --mdc-plain-tooltip-supporting-text-weight: lighter;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-label-text-font: EYInterstate;
  --mdc-filled-text-field-label-text-size: 15px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: EYInterstate;
  --mdc-outlined-text-field-label-text-size: 15px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: EYInterstate;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 15px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 15px;
  --mat-form-field-subscript-text-font: EYInterstate;
  --mat-form-field-subscript-text-line-height: 1.142rem;
  --mat-form-field-subscript-text-size: 0.857rem;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: lighter;
}

html {
  --mat-select-trigger-text-font: EYInterstate;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 15px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: EYInterstate;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: EYInterstate;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 15px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: EYInterstate;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: EYInterstate;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: normal;
  --mat-switch-label-text-weight: 400;
}

html {
  --mat-radio-label-text-font: EYInterstate;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: normal;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: EYInterstate;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: EYInterstate;
  --mat-menu-item-label-text-size: 15px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: EYInterstate;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 15px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: EYInterstate;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: EYInterstate;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1.142rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.857rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: lighter;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px EYInterstate;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-font: EYInterstate;
  --mat-paginator-container-text-line-height: 1.142rem;
  --mat-paginator-container-text-size: 0.857rem;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: lighter;
  --mat-paginator-select-trigger-text-size: 0.857rem;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: EYInterstate;
  --mat-tab-header-label-text-size: 1rem;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 1rem;
  --mat-tab-header-label-text-weight: 700;
}

html {
  --mat-checkbox-label-text-font: EYInterstate;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: normal;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: EYInterstate;
  --mdc-text-button-label-text-size: 1rem;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 700;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: EYInterstate;
  --mdc-filled-button-label-text-size: 1rem;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 700;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: EYInterstate;
  --mdc-protected-button-label-text-size: 1rem;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 700;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: EYInterstate;
  --mdc-outlined-button-label-text-size: 1rem;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 700;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: EYInterstate;
  --mdc-extended-fab-label-text-size: 1rem;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 700;
}

html {
  --mdc-snackbar-supporting-text-font: EYInterstate;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: EYInterstate;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: EYInterstate;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: EYInterstate;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #2e2e38;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #ffe600;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #2e2e38;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2e2e38;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #ffe600;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ffe600;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #ffe600;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ffe600;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: EYInterstate;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 15px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: EYInterstate;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 15px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #2e2e38;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #ffe600;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #2e2e38;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2e2e38;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #ffe600;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ffe600;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #ffe600;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ffe600;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: EYInterstate;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 15px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: EYInterstate;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 15px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: EYInterstate;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: EYInterstate;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2e2e38;
  --mdc-linear-progress-track-color: rgba(46, 46, 56, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ffe600;
  --mdc-linear-progress-track-color: rgba(255, 230, 0, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: EYInterstate;
  --mdc-plain-tooltip-supporting-text-size: 0.857rem;
  --mdc-plain-tooltip-supporting-text-weight: lighter;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #2e2e38;
  --mdc-filled-text-field-focus-active-indicator-color: #2e2e38;
  --mdc-filled-text-field-focus-label-text-color: rgba(46, 46, 56, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #2e2e38;
  --mdc-outlined-text-field-focus-outline-color: #2e2e38;
  --mdc-outlined-text-field-focus-label-text-color: rgba(46, 46, 56, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(46, 46, 56, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #ffe600;
  --mdc-filled-text-field-focus-active-indicator-color: #ffe600;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 230, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #ffe600;
  --mdc-outlined-text-field-focus-outline-color: #ffe600;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 230, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(255, 230, 0, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: EYInterstate;
  --mdc-filled-text-field-label-text-size: 15px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: EYInterstate;
  --mdc-outlined-text-field-label-text-size: 15px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: EYInterstate;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 15px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 15px;
  --mat-form-field-subscript-text-font: EYInterstate;
  --mat-form-field-subscript-text-line-height: 1.142rem;
  --mat-form-field-subscript-text-size: 0.857rem;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: lighter;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(46, 46, 56, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 230, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: EYInterstate;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 15px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: EYInterstate;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: EYInterstate;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 15px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #2e2e38;
  --mdc-chip-elevated-selected-container-color: #2e2e38;
  --mdc-chip-elevated-disabled-container-color: #2e2e38;
  --mdc-chip-flat-disabled-selected-container-color: #2e2e38;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: #ffe600;
  --mdc-chip-elevated-selected-container-color: #ffe600;
  --mdc-chip-elevated-disabled-container-color: #ffe600;
  --mdc-chip-flat-disabled-selected-container-color: #ffe600;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-selected-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
  --mat-chip-selected-disabled-trailing-icon-color: black;
  --mat-chip-selected-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: EYInterstate;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #23232f;
  --mdc-switch-selected-handle-color: #23232f;
  --mdc-switch-selected-hover-state-layer-color: #23232f;
  --mdc-switch-selected-pressed-state-layer-color: #23232f;
  --mdc-switch-selected-focus-handle-color: #282836;
  --mdc-switch-selected-hover-handle-color: #282836;
  --mdc-switch-selected-pressed-handle-color: #282836;
  --mdc-switch-selected-focus-track-color: #c4c4cd;
  --mdc-switch-selected-hover-track-color: #c4c4cd;
  --mdc-switch-selected-pressed-track-color: #c4c4cd;
  --mdc-switch-selected-track-color: #c4c4cd;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #ffe300;
  --mdc-switch-selected-handle-color: #ffe300;
  --mdc-switch-selected-hover-state-layer-color: #ffe300;
  --mdc-switch-selected-pressed-state-layer-color: #ffe300;
  --mdc-switch-selected-focus-handle-color: #ffd500;
  --mdc-switch-selected-hover-handle-color: #ffd500;
  --mdc-switch-selected-pressed-handle-color: #ffd500;
  --mdc-switch-selected-focus-track-color: #ffee4d;
  --mdc-switch-selected-hover-track-color: #ffee4d;
  --mdc-switch-selected-pressed-track-color: #ffee4d;
  --mdc-switch-selected-track-color: #ffee4d;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: EYInterstate;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: normal;
  --mat-switch-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #2e2e38;
  --mdc-radio-selected-hover-icon-color: #2e2e38;
  --mdc-radio-selected-icon-color: #2e2e38;
  --mdc-radio-selected-pressed-icon-color: #2e2e38;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #2e2e38;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ffe600;
  --mdc-radio-selected-hover-icon-color: #ffe600;
  --mdc-radio-selected-icon-color: #ffe600;
  --mdc-radio-selected-pressed-icon-color: #ffe600;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #ffe600;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: EYInterstate;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: normal;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #2e2e38;
  --mdc-slider-focus-handle-color: #2e2e38;
  --mdc-slider-hover-handle-color: #2e2e38;
  --mdc-slider-active-track-color: #2e2e38;
  --mdc-slider-inactive-track-color: #2e2e38;
  --mdc-slider-with-tick-marks-inactive-container-color: #2e2e38;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #2e2e38;
  --mat-slider-hover-state-layer-color: rgba(46, 46, 56, 0.05);
  --mat-slider-focus-state-layer-color: rgba(46, 46, 56, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #ffe600;
  --mdc-slider-focus-handle-color: #ffe600;
  --mdc-slider-hover-handle-color: #ffe600;
  --mdc-slider-active-track-color: #ffe600;
  --mdc-slider-inactive-track-color: #ffe600;
  --mdc-slider-with-tick-marks-inactive-container-color: #ffe600;
  --mdc-slider-with-tick-marks-active-container-color: black;
  --mat-slider-ripple-color: #ffe600;
  --mat-slider-hover-state-layer-color: rgba(255, 230, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 230, 0, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}

html {
  --mdc-slider-label-label-text-font: EYInterstate;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: EYInterstate;
  --mat-menu-item-label-text-size: 15px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #2e2e38;
  --mdc-radio-selected-hover-icon-color: #2e2e38;
  --mdc-radio-selected-icon-color: #2e2e38;
  --mdc-radio-selected-pressed-icon-color: #2e2e38;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ffe600;
  --mdc-radio-selected-hover-icon-color: #ffe600;
  --mdc-radio-selected-icon-color: #ffe600;
  --mdc-radio-selected-pressed-icon-color: #ffe600;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2e2e38;
  --mdc-checkbox-selected-hover-icon-color: #2e2e38;
  --mdc-checkbox-selected-icon-color: #2e2e38;
  --mdc-checkbox-selected-pressed-icon-color: #2e2e38;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2e2e38;
  --mdc-checkbox-selected-hover-state-layer-color: #2e2e38;
  --mdc-checkbox-selected-pressed-state-layer-color: #2e2e38;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #ffe600;
  --mdc-checkbox-selected-hover-icon-color: #ffe600;
  --mdc-checkbox-selected-icon-color: #ffe600;
  --mdc-checkbox-selected-pressed-icon-color: #ffe600;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ffe600;
  --mdc-checkbox-selected-hover-state-layer-color: #ffe600;
  --mdc-checkbox-selected-pressed-state-layer-color: #ffe600;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #2e2e38;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: EYInterstate;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 15px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: EYInterstate;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: EYInterstate;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1.142rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.857rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: lighter;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px EYInterstate;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: EYInterstate;
  --mat-paginator-container-text-line-height: 1.142rem;
  --mat-paginator-container-text-size: 0.857rem;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: lighter;
  --mat-paginator-select-trigger-text-size: 0.857rem;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #2e2e38;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #2e2e38;
  --mat-tab-header-active-ripple-color: #2e2e38;
  --mat-tab-header-inactive-ripple-color: #2e2e38;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #2e2e38;
  --mat-tab-header-active-hover-label-text-color: #2e2e38;
  --mat-tab-header-active-focus-indicator-color: #2e2e38;
  --mat-tab-header-active-hover-indicator-color: #2e2e38;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ffe600;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ffe600;
  --mat-tab-header-active-ripple-color: #ffe600;
  --mat-tab-header-inactive-ripple-color: #ffe600;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ffe600;
  --mat-tab-header-active-hover-label-text-color: #ffe600;
  --mat-tab-header-active-focus-indicator-color: #ffe600;
  --mat-tab-header-active-hover-indicator-color: #ffe600;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #2e2e38;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ffe600;
  --mat-tab-header-with-background-foreground-color: black;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: EYInterstate;
  --mat-tab-header-label-text-size: 1rem;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 1rem;
  --mat-tab-header-label-text-weight: 700;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #ffe600;
  --mdc-checkbox-selected-hover-icon-color: #ffe600;
  --mdc-checkbox-selected-icon-color: #ffe600;
  --mdc-checkbox-selected-pressed-icon-color: #ffe600;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ffe600;
  --mdc-checkbox-selected-hover-state-layer-color: #ffe600;
  --mdc-checkbox-selected-pressed-state-layer-color: #ffe600;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2e2e38;
  --mdc-checkbox-selected-hover-icon-color: #2e2e38;
  --mdc-checkbox-selected-icon-color: #2e2e38;
  --mdc-checkbox-selected-pressed-icon-color: #2e2e38;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2e2e38;
  --mdc-checkbox-selected-hover-state-layer-color: #2e2e38;
  --mdc-checkbox-selected-pressed-state-layer-color: #2e2e38;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: EYInterstate;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: normal;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #2e2e38;
  --mat-text-button-state-layer-color: #2e2e38;
  --mat-text-button-ripple-color: rgba(46, 46, 56, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ffe600;
  --mat-text-button-state-layer-color: #ffe600;
  --mat-text-button-ripple-color: rgba(255, 230, 0, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #2e2e38;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ffe600;
  --mdc-filled-button-label-text-color: black;
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #2e2e38;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ffe600;
  --mdc-protected-button-label-text-color: black;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #2e2e38;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #2e2e38;
  --mat-outlined-button-ripple-color: rgba(46, 46, 56, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ffe600;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #ffe600;
  --mat-outlined-button-ripple-color: rgba(255, 230, 0, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: EYInterstate;
  --mdc-text-button-label-text-size: 1rem;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 700;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: EYInterstate;
  --mdc-filled-button-label-text-size: 1rem;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 700;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: EYInterstate;
  --mdc-protected-button-label-text-size: 1rem;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 700;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: EYInterstate;
  --mdc-outlined-button-label-text-size: 1rem;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 700;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #2e2e38;
  --mat-icon-button-state-layer-color: #2e2e38;
  --mat-icon-button-ripple-color: rgba(46, 46, 56, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ffe600;
  --mat-icon-button-state-layer-color: #ffe600;
  --mat-icon-button-ripple-color: rgba(255, 230, 0, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #2e2e38;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #ffe600;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #2e2e38;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #ffe600;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: EYInterstate;
  --mdc-extended-fab-label-text-size: 1rem;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 700;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #ffe600;
}

html {
  --mdc-snackbar-supporting-text-font: EYInterstate;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: EYInterstate;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: EYInterstate;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: EYInterstate;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #2e2e38;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #ffe600;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #2e2e38;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #ffe600;
  --mat-badge-text-color: black;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: EYInterstate;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: EYInterstate;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: EYInterstate;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 15px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: EYInterstate;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 15px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #2e2e38;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(46, 46, 56, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(46, 46, 56, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(46, 46, 56, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #2e2e38;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(46, 46, 56, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #ffe600;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 230, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 230, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 230, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 230, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ffe600;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: EYInterstate;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 1rem;
  --mat-datepicker-calendar-body-label-text-weight: 700;
  --mat-datepicker-calendar-period-button-text-size: 1rem;
  --mat-datepicker-calendar-period-button-text-weight: 700;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: EYInterstate;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: EYInterstate;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 0.857rem;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 0.857rem;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #2e2e38;
}
.mat-icon.mat-accent {
  --mat-icon-color: #ffe600;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #2e2e38;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #2e2e38;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #2e2e38;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: #ffe600;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: #ffe600;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: #ffe600;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: EYInterstate;
  --mat-stepper-header-label-text-font: EYInterstate;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 15px;
  --mat-stepper-header-selected-state-label-text-size: 15px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #2e2e38;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #ffe600;
  --mat-toolbar-container-text-color: black;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: EYInterstate;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: EYInterstate;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

:root {
  --color-primary: #2e2e38;
  --color-primary-50: #e1e1e6;
  --color-primary-100: #f6f6fa;
  --color-primary-200: #eaeaf2;
  --color-primary-300: #c4c4cd;
  --color-primary-400: #747480;
  --color-primary-500: #2e2e38;
  --color-primary-600: #23232f;
  --color-primary-900: #282836;
  --color-accent: #ffe600;
  --color-warn: #b9251c;
  --color-white: #fff;
  --spacing-small: 10px;
  --spacing-default: 12px;
  --spacing-big: 15px;
}

:root {
  --font-size-title: 1.2142857143rem;
  --font-size-title-h6: 1.1428571429rem;
  --font-size-small: 0.8571428571rem;
  --font-size-medium: 1rem;
  --dark-grey: #2e2e38;
  --primary-white: #ffffff;
  --primary-yellow: #ffe600;
  --color-light-gray: #dadada;
  --color-blue: #155cb4;
  --mat-tab-header-active-focus-indicator-color: var(--primary-yellow);
  --mdc-tab-indicator-active-indicator-color: var(--primary-yellow);
  --mat-icon-button-state-layer-color: transparent;
  --mat-menu-item-label-text-weight: 300;
  --mat-form-field-container-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-container-shape: 0;
  --mat-filled-button-icon-spacing: 3px;
  --mat-filled-button-icon-offset: 0;
  --mat-form-field-container-text-line-height: 15.75px;
  --mdc-filled-text-field-label-text-color: #000000de;
  --mdc-filled-text-field-container-color: #f6f6fa;
  --mdc-filled-text-field-disabled-container-color: #f6f6fa;
  --mat-mdc-form-field-flex-height: 51px;
  --mat-form-field-container-height: 52px;
  --mat-form-field-filled-with-label-container-padding-top: 20px;
  --mat-select-arrow-transform: translateY(-7px) translateX(-4px);
  --mdc-filled-text-field-error-label-text-color: inherit;
  --mat-form-field-state-layer-color: inherit;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-subscript-text-line-height: 0;
  --mat-form-field-container-vertical-padding: 8px;
  --mdc-checkbox-state-layer-size: 0;
  --mat-expansion-container-text-line-height: 16px;
  --mdc-checkbox-unselected-icon-color: #282836;
  --mdc-checkbox-unselected-focus-state-layer-color: #282836;
  --mdc-checkbox-unselected-hover-state-layer-color: #282836;
  --mdc-checkbox-unselected-pressed-state-layer-color: #282836;
  --mat-option-label-text-size: 14px;
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.12);
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.26);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-select-trigger-text-line-height: 15.75px;
  --mat-mdc-icon-button-width: 34px;
  --mat-menu-item-label-text-size: 14px;
  --mdc-filled-button-disabled-label-text-color: #00000042;
  --mdc-list-list-item-focus-label-text-color: inherit;
  --mdc-list-list-item-hover-label-text-color: inherit;
  --mdc-list-list-item-label-text-color: inherit;
  --mdc-list-list-item-focus-state-layer-opacity: 0;
  --mdc-list-list-item-hover-state-layer-opacity: 0;
  --mat-text-button-pressed-state-layer-opacity: 0;
  --mat-text-button-hover-state-layer-opacity: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;
  --mat-icon-button-hover-state-layer-opacity: 0;
  --mdc-circular-progress-size: 54px;
  --mdc-circular-progress-active-indicator-width: 54px;
  --mat-badge-background-color: #b9251c;
  --mat-badge-container-offset: 0px 0px -18px -10px;
  --kendo-color-base-active: var(--dark-grey);
  --kendo-color-base-hover: var(--dark-grey);
  --kendo-color-on-primary: var(--dark-grey);
  --kendo-color-base-emphasis: var(--primary-white);
  --kendo-color-base-on-subtle: var(--primary-white);
  --kendo-color-base: var(--primary-white);
  --kendo-color-primary-subtle-active: var(--primary-white);
  --kendo-color-primary-subtle-hover: var(--primary-white);
  --kendo-color-primary-subtle: var(--primary-white);
  --kendo-color-surface: var(--primary-white);
  --kendo-color-primary-emphasis: var(--primary-yellow);
  --kendo-color-primary-hover: var(--primary-yellow);
  --kendo-color-primary-on-subtle: var(--primary-yellow);
  --kendo-color-primary-on-surface: var(--primary-yellow);
  --kendo-color-primary: var(--primary-yellow);
  --kendo-border-radius-none: 0px;
  --kendo-border-radius-md: var(--kendo-border-radius-none);
}

.k-button {
  font-size: 14px;
}

.k-button-solid-base:hover,
.k-button-solid-base.k-hover {
  background-color: var(--color-primary-50);
}

.k-button-solid-primary:active,
.k-button-solid-primary.k-active {
  border-color: #ffe600;
  background-color: #ffe600;
}

.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
  box-shadow: unset;
}

.k-list-item:hover,
.k-list-optionlabel:hover,
.k-list-item.k-hover,
.k-hover.k-list-optionlabel {
  background-color: rgba(0, 0, 0, 0.0392156863);
}

.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  background-color: rgba(0, 0, 0, 0.0392156863);
}

.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
  background-color: rgba(0, 0, 0, 0.0392156863);
}

.k-list-content::-webkit-scrollbar-thumb {
  height: 30%;
  background-color: var(--color-primary-600);
  outline: 1px solid var(--color-primary-600);
}
.k-list-content::-webkit-scrollbar {
  background-color: #dadada;
}
.k-list-content::-webkit-scrollbar:vertical {
  width: 5px;
}
.k-list-content::-webkit-scrollbar:horizontal {
  height: 5px;
}

.k-button-flat-primary {
  color: var(--mdc-list-list-item-label-text-color);
  font-weight: 600;
}

.k-calendar .k-calendar-td:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
  background-color: var(--color-primary-300);
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
  background-color: rgba(46, 46, 56, 0.2);
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
  background-color: #23232f;
  color: #ffffff;
}

.k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
  background-color: #23232f;
  color: #ffffff;
}

.k-calendar .k-calendar-view .k-today {
  color: unset;
}

.k-option-inactive {
  opacity: 0.5;
}

.dcms-mat-display-4, h2 {
  margin-bottom: 0;
}

.dcms-mat-display-3, h3 {
  margin-bottom: 0;
}

.dcms-mat-display-2, h4 {
  margin-bottom: 0;
}

.dcms-mat-display-1, h5 {
  margin-bottom: 0;
}

.dcms-mat-option-disabled {
  opacity: 0.2;
}

h5 {
  margin-bottom: 30px;
}

h6 {
  margin-bottom: 0;
}

p,
span {
  font-size: 1rem;
}

.mat-display-4,
.dcms-mat-display-4,
h2,
.mat-typography .mat-display-4 {
  letter-spacing: normal;
  line-height: 2.857rem;
  font-family: EYInterstate;
  font-size: 2.285rem !important;
  color: var(--color-primary-600);
  font-weight: 700 !important;
  font-style: normal;
}

.mat-display-2,
.dcms-mat-display-2,
h4,
.mat-typography .mat-display-2 {
  letter-spacing: normal;
  line-height: 2rem;
  font-family: EYInterstate;
  font-size: 1.714rem !important;
  color: var(--color-primary-600);
  font-weight: 700 !important;
  font-style: normal;
}

.mat-display-1,
.dcms-mat-display-1,
h5,
.mat-typography .mat-display-1 {
  letter-spacing: normal;
  line-height: 1.714rem;
  font-family: EYInterstate;
  font-size: 1.428rem !important;
  color: var(--color-primary-600);
  font-weight: 700 !important;
  font-style: normal;
}

.mat-h1,
.mat-headline,
h6,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  line-height: 1.28rem;
  font-family: EYInterstate;
  font-size: 1.142rem !important;
  color: var(--color-primary-600);
  font-weight: 700 !important;
  font-style: normal;
}

.dcms-bulk-upload-dialog.modal-dialog {
  --bs-modal-width: 712px;
}

.mat-mdc-button-base.mat-mdc-unelevated-button, .mat-mdc-button-base.mat-mdc-raised-button, .mat-mdc-button-base.mat-mdc-button {
  border-radius: 0;
  line-height: 24px;
  height: 44px;
  font-weight: 700;
}
.mat-mdc-button-base.mat-mdc-icon-button.mat-accent {
  height: 44px;
  width: 48px;
  border-radius: 0;
  color: var(--color-primary);
  background-color: var(--color-accent);
}
.mat-mdc-button-base.mat-mdc-icon-button svg {
  padding: 2px;
}

.mat-mdc-button.button--link {
  font-size: 12px;
  font-weight: 400;
}
.mat-mdc-button.button--link:not(.mat-mdc-button-disabled) {
  color: var(--color-blue);
}

app-export-button .mat-mdc-button-base.mat-mdc-icon-button.mat-accent {
  height: auto;
}
app-export-button span {
  font-weight: 600;
}
app-export-button .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0 14px;
}
app-export-button button {
  line-height: 40px !important;
}

.mdc-button {
  min-width: 0 !important;
}

.filter__action .mdc-button {
  padding: 0 16px;
}

.buttons-container {
  justify-content: left;
  border: none;
  display: flex;
  margin-top: 4vh;
  gap: 15px;
}

.button-container {
  float: right;
}

.three-dots {
  color: #dadada;
  cursor: pointer;
}

.three-dots:hover {
  color: #747480;
}

.view-heading-container {
  padding: 0.4rem 0.4rem 0.4rem 0;
  display: flex;
  flex-direction: column;
}
.view-heading-container span:nth-of-type(1) {
  line-height: 1.5714rem;
  padding: 0.1428rem;
  font-family: EYInterstate;
  font-size: 1rem !important;
  color: #2e2e38;
  font-weight: 400 !important;
  font-style: normal;
}
.view-heading-container span:nth-of-type(2) {
  line-height: 1.1428rem;
  padding: 0.1428rem;
  font-family: EYInterstate;
  font-size: 0.8571rem !important;
  color: #2e2e38;
  font-weight: 300 !important;
  font-style: normal;
}

.view-edit-action-div {
  width: 8em;
  background-color: #ffffff;
  display: none;
  position: absolute;
  z-index: 9999;
  margin-left: 0.4em;
  border: 0.1rem solid #c4c4cd;
}

.view-sort-action-div {
  width: 8.125em;
  background-color: #ffffff;
  display: none;
  position: absolute;
  z-index: 9999;
  margin-left: 4.5em;
  border: 0.1rem solid #c4c4cd;
  margin-top: 0.5em;
}
.view-sort-action-div div:nth-of-type(1) {
  padding-left: 0em !important;
}
.view-sort-action-div div:nth-of-type(2) {
  padding-left: 0em !important;
}
.view-sort-action-div div:nth-of-type(3) {
  padding-left: 0em !important;
}
.view-sort-action-div div:nth-of-type(4) {
  padding-left: 0em !important;
}

.action-text-container {
  cursor: pointer;
  margin-left: 1.2em;
  font-family: EYInterstate;
  font-size: 1rem !important;
  color: #2e2e38;
  font-weight: 300 !important;
  font-style: normal;
  margin-top: 1vh;
  height: 4.2vh;
}
.action-text-container i {
  float: left;
  margin-right: 1em;
}

.float-left {
  float: left;
}

.mat-mdc-list-base .mat-mdc-list-item.custom-fields-creator__modules-list-item {
  border: none !important;
  cursor: pointer;
}
.mat-mdc-list-base .mat-mdc-list-item.custom-fields-creator__modules-list-item .mdc-list-item__primary-text {
  position: absolute;
  top: 0;
  font-size: 1rem !important;
}
.mat-mdc-list-base .mat-mdc-list-item.custom-fields-creator__modules-list-item--active .mdc-list-item__primary-text {
  font-weight: 600 !important;
}
.mat-mdc-list-base .mat-mdc-list-item.custom-fields-creator__modules-list-item .mdc-list-item__content {
  padding: var(--spacing-small) var(--spacing-big) !important;
}

.custom-fields-creator__modules-list .mdc-list-item {
  padding: 0 !important;
}

.custom-fields-creator__tabs .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--mat-tab-header-inactive-label-text-color) !important;
}

.custom-fields-creator__modules-title {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.page-title__back-button .mat-icon {
  font-size: 15px;
  height: 15px;
  width: 15px;
}

.overview-container {
  margin-top: 2em;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.overview-grid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 0;
  width: calc(100% - 20px);
  gap: 20px;
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}

.close-icon {
  color: #ffffff !important;
}

.modal-header {
  background: #282836 !important;
  border-radius: 0;
  height: 6vh;
  padding: 16px 14px 16px 20px;
  justify-content: space-between;
}
.modal-header .modal-title {
  font-family: EYInterstate;
  font-size: 20px !important;
  color: white;
  font-weight: 400 !important;
  font-style: normal;
}
.modal-header .close {
  background: none;
  border: none;
}

.modal {
  --bs-modal-width: 39.5em;
  --bs-modal-margin: none !important;
}
.modal :has(.confirmation-dialog) {
  --bs-modal-width: 50em;
}

.modal-dialog:not(.modal-dialog:has(.bulkupload-modal, .confirmation-dialog, .search-list-dialog-modal)) {
  margin-right: 0 !important;
}

.modal-content {
  border-radius: 0;
  width: 100%;
  border: none;
  margin-top: 0 !important;
}
.modal-content:not(:has(.bulkupload-modal, .confirmation-dialog, .search-list-dialog-modal)) {
  margin-top: 0 !important;
  height: 100vh !important;
}
.modal-content:has(.confirmation-modal) {
  margin-top: 45% !important;
  width: 450px;
}
.modal-content:has(.confirmation-dialog) {
  top: 30vh;
}
.modal-content:has(.search-list-dialog-modal) {
  top: 20vh;
}

.modal-footer {
  justify-content: left;
  border: none;
}

.mat-expansion-panel-body::-webkit-scrollbar-thumb {
  height: 30%;
  background-color: var(--color-primary-600);
  outline: 1px solid var(--color-primary-600);
}
.mat-expansion-panel-body::-webkit-scrollbar {
  background-color: #dadada;
}
.mat-expansion-panel-body::-webkit-scrollbar:vertical {
  width: 5px;
}
.mat-expansion-panel-body::-webkit-scrollbar:horizontal {
  height: 5px;
}

.dcms-extend-session-dialog.modal-dialog {
  --bs-modal-width: 712px;
}

.filter-label {
  margin-left: 1.3rem;
  margin-top: 1vh;
}

.filter-grid {
  border: 1px solid var(--color-primary-50);
  max-height: 100%;
  border-top: none;
  border-right: none;
}

.hr-divider {
  background: #ffffff;
  border-top: 0.188rem solid #e1e1e6;
}

.mat-mdc-form-field {
  position: relative;
  display: inline-block;
  text-align: left;
}
.mat-mdc-form-field .mat-date-range-input-container {
  padding: 3.5px 0 10.5px 0;
}
.mat-mdc-form-field-infix label {
  max-width: calc(100% - 10px) !important;
}
.mat-mdc-form-field-infix label.mdc-floating-label--float-above {
  max-width: calc(100% + 30px) !important;
}
.mat-mdc-form-field .mdc-text-field__input {
  padding-top: 3px;
}
.mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  margin-bottom: 3px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-flex {
  border-radius: 0;
  background-color: var(--color-primary-100);
  height: var(--mat-mdc-form-field-flex-height);
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-label {
  color: var(--color-primary);
}
.mat-mdc-form-field.mat-form-field-appearance-fill.dcms-mat-select--no-label .mat-mdc-form-field-infix {
  border-top: 4px solid transparent;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-disabled .mat-mdc-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-disabled .mat-mdc-select-arrow {
  filter: contrast(0);
}
.mat-mdc-form-field .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mdc-floating-label--float-above {
  margin-top: 6px !important;
  margin-left: 0em;
}
.mat-mdc-form-field.mat-form-field-invalid .mdc-text-field--invalid .mat-mdc-form-field-required-marker.mdc-floating-label--required {
  color: var(--mat-form-field-error-text-color);
  margin-left: 2px;
}
.mat-mdc-form-field .mdc-floating-label {
  color: inherit !important;
}
.mat-mdc-form-field .mdc-floating-label--required::after {
  margin-left: 4px !important;
}
.mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background-color: var(--mdc-filled-text-field-container-color);
  flex: auto;
  will-change: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px !important;
  margin-left: 0;
  margin-top: 0.6vh;
}
.mat-mdc-form-field .mat-mdc-form-field-error-wrapper {
  padding: 0 10.5px !important;
}
.mat-mdc-form-field .mat-mdc-form-field-error-wrapper .mat-mdc-form-field-error {
  display: flex !important;
  margin-top: 4px !important;
}
.mat-mdc-form-field .mdc-text-field {
  padding: 0 10.5px !important;
  align-items: center !important;
}
.mat-mdc-form-field .mdc-text-field--disabled {
  color: var(--mat-select-disabled-trigger-text-color);
}
.mat-mdc-form-field .mdc-text-field--disabled .mat-mdc-input-element {
  pointer-events: none !important;
  cursor: none;
}
.mat-mdc-form-field .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-width: 2px;
}
.mat-mdc-form-field .mat-mdc-form-field-textarea-control.mat-mdc-input-element {
  height: auto !important;
  margin-top: 3px;
}

.mat-form-field-disabled .mdc-line-ripple--deactivating::before {
  border: none !important;
}

.mdc-list-item__primary-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  height: inherit;
  color: inherit;
}
.mdc-list-item__primary-text .ng-star-inserted {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.mat-form-field-label {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  --mat-form-field-subscript-text-line-height: auto;
}

:host ::ng-deep .mdc-line-ripple {
  height: 0em;
  background-image: none !important;
  background-color: #747480 !important;
}

.mat-mdc-form-field:has(textarea) .mat-mdc-text-field-wrapper {
  max-height: 300px !important;
}
.mat-mdc-form-field:has(textarea) .mat-mdc-text-field-wrapper textarea {
  resize: none;
}

.dcms-hierarchy.modal-dialog {
  --bs-modal-width: 884px;
  margin-top: 70px;
}

.mat-icon {
  cursor: pointer;
}

.bi-caret-down-fill::before {
  font-size: 14px;
}

.mat-mdc-button > .mat-icon,
.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
  font-size: 15px;
  height: 15px;
  width: 15px;
  line-height: 15px;
}

.mat-mdc-unelevated-button > .mat-icon {
  margin-top: 2px;
}

.overview-tile-icons {
  font-size: 1em;
  vertical-align: middle;
}

.active-icon {
  margin-left: 0.8214rem;
}

.search-icon {
  cursor: pointer;
  float: right;
  margin-top: -2rem;
  margin-right: 0.625rem;
}

html {
  font-size: 14px !important;
  height: 100%;
}

body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  font-family: EYInterstate;
  font-size: 1rem;
}
body .cdk-global-overlay-wrapper,
body .cdk-overlay-container {
  z-index: 9999 !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

:host ::ng-deep ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: EYInterstate;
  font-size: 0.75rem !important;
  color: #2e2e38;
  font-weight: 300 !important;
  font-style: normal;
  /* Firefox */
}
:host ::ng-deep :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: EYInterstate;
  font-size: 0.75rem !important;
  color: #2e2e38;
  font-weight: 300 !important;
  font-style: normal;
}
:host ::ng-deep ::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: EYInterstate;
  font-size: 0.75rem !important;
  color: #2e2e38;
  font-weight: 300 !important;
  font-style: normal;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.input-grid {
  margin-top: 1.2rem !important;
}

.wrapper-div {
  width: 100%;
}

.sort-wrapper > span {
  float: right;
  cursor: pointer;
}

.dcms-manage-hierarchy.modal-dialog {
  --bs-modal-width: 600px;
  margin-top: 70px;
}

.mat-mdc-checkbox .mdc-checkbox__background {
  border-radius: 0 !important;
  width: 16px !important;
  height: 16px !important;
  border-width: 1px !important;
  border-color: #282836 !important;
}
.mat-mdc-checkbox .mdc-checkbox__mixedmark {
  width: calc(100% - 6px);
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: scale(0.8);
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2e2e38;
  --mdc-checkbox-selected-hover-icon-color: #2e2e38;
  --mdc-checkbox-selected-icon-color: #2e2e38;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2e2e38;
  --mdc-checkbox-selected-hover-state-layer-color: #2e2e38;
  --mdc-checkbox-selected-pressed-state-layer-color: #2e2e38;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

div.mat-mdc-menu-panel {
  box-shadow: none;
  border: 1px solid #e1e1e6;
  border-radius: 0;
  min-height: 34px;
}
div.mat-mdc-menu-panel .mat-mdc-menu-content:not(:empty) {
  padding: 0;
}
div.mat-mdc-menu-panel .mat-mdc-menu-item {
  height: 34px;
  display: flex;
  align-items: center;
  font-weight: 300;
  min-height: 0 !important;
}

.mat-mdc-option {
  min-height: 42px;
  padding: 0 16px;
}
.mat-mdc-option .mdc-list-item__primary-text {
  font-weight: 300;
  font-size: 14px;
  margin: 0 !important;
  width: 100%;
  text-wrap: nowrap !important;
  line-height: 42px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: inline-block;
}
.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none;
}
.mat-mdc-option:focus.mdc-list-item {
  background-color: #ffffff;
}
.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: var(--mat-option-focus-state-layer-color);
}
.mat-mdc-option .mat-icon, .mat-mdc-option .mat-pseudo-checkbox-full {
  margin-right: 0;
}
.mat-mdc-option.mdc-list-item--disabled {
  opacity: 0.5;
}
.mat-mdc-option.mdc-list-item--disabled .mat-mdc-option-pseudo-checkbox, .mat-mdc-option.mdc-list-item--disabled .mdc-list-item__primary-text, .mat-mdc-option.mdc-list-item--disabled > mat-icon {
  opacity: 1 !important;
}

mat-option.mat-select-hide-options {
  display: none;
}

.mat-mdc-option.contains-mat-select-search mat-pseudo-checkbox {
  display: none;
}

.mat-mdc-select-panel {
  border-radius: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  box-shadow: var(--mat-select-container-elevation-shadow);
}
.mat-mdc-select-panel:focus {
  box-shadow: var(--mat-select-container-elevation-shadow) !important;
}

.mat-mdc-select .mat-mdc-select-min-line {
  white-space: normal;
}
.mat-mdc-select .mat-mdc-select-trigger {
  height: auto;
}
.mat-mdc-select .mat-mdc-select-arrow-wrapper {
  margin-bottom: auto;
  margin-left: 7px;
}
.mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  border-left: 15px solid transparent !important;
  border-right: none !important;
  border-top: 15px solid transparent !important;
  border-image-source: url("../../../assets/images/down_arrow.png") !important;
  border-image-repeat: stretch !important;
}
.mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg {
  display: none;
}

.mat-select-search-input {
  border: none;
}

.mat-mdc-select-value {
  margin-top: 3px;
  padding-top: 3px;
}

.mat-step-header {
  border: 1px solid var(--color-primary-300);
}
.mat-step-header.mat-horizontal-stepper-header {
  height: 42px;
  padding-left: 15px;
  padding-right: 15px;
}
.mat-step-header .mat-step-label {
  color: var(--color-primary-300);
}
.mat-step-header[aria-selected=true] {
  background-color: var(--color-primary-900);
}
.mat-step-header[aria-selected=true]:hover {
  background-color: var(--color-primary-900);
}
.mat-step-header[aria-selected=true] .mat-step-label.mat-step-label-active {
  color: #fff;
}
.mat-step-header[aria-selected=true] .mat-step-icon-state-edit {
  background: var(--color-primary-900);
  border: 1px solid #fff;
}
.mat-step-header[aria-selected=true]:not([aria-labelledby=dcms-mat-stepper__step--completed]) .mat-icon {
  display: none;
}
.mat-step-header[aria-labelledby=dcms-mat-stepper__step--completed] {
  background-color: var(--color-accent);
  border: 1px solid var(--color-accent);
}
.mat-step-header[aria-labelledby=dcms-mat-stepper__step--completed]:hover {
  background-color: var(--color-accent);
  border: 1px solid var(--color-accent);
}
.mat-step-header .mat-step-icon-content > span {
  display: none;
}
.mat-step-header .mat-step-icon-state-number {
  background-color: transparent;
  border: 1px solid var(--color-primary-300);
}

.mat-horizontal-stepper-header {
  height: 42px;
  padding-left: 15px;
  padding-right: 15px;
}

div.mat-stepper-horizontal-line {
  margin: 0;
  border-top-width: 2px;
  border-top-color: var(--color-primary-50);
}

:root {
  --font-size-title: 1.2142857143rem;
  --font-size-title-h6: 1.1428571429rem;
  --font-size-small: 0.8571428571rem;
  --font-size-medium: 1rem;
  --dark-grey: #2e2e38;
  --primary-white: #ffffff;
  --primary-yellow: #ffe600;
  --color-light-gray: #dadada;
  --color-blue: #155cb4;
  --mat-tab-header-active-focus-indicator-color: var(--primary-yellow);
  --mdc-tab-indicator-active-indicator-color: var(--primary-yellow);
  --mat-icon-button-state-layer-color: transparent;
  --mat-menu-item-label-text-weight: 300;
  --mat-form-field-container-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-container-shape: 0;
  --mat-filled-button-icon-spacing: 3px;
  --mat-filled-button-icon-offset: 0;
  --mat-form-field-container-text-line-height: 15.75px;
  --mdc-filled-text-field-label-text-color: #000000de;
  --mdc-filled-text-field-container-color: #f6f6fa;
  --mdc-filled-text-field-disabled-container-color: #f6f6fa;
  --mat-mdc-form-field-flex-height: 51px;
  --mat-form-field-container-height: 52px;
  --mat-form-field-filled-with-label-container-padding-top: 20px;
  --mat-select-arrow-transform: translateY(-7px) translateX(-4px);
  --mdc-filled-text-field-error-label-text-color: inherit;
  --mat-form-field-state-layer-color: inherit;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-subscript-text-line-height: 0;
  --mat-form-field-container-vertical-padding: 8px;
  --mdc-checkbox-state-layer-size: 0;
  --mat-expansion-container-text-line-height: 16px;
  --mdc-checkbox-unselected-icon-color: #282836;
  --mdc-checkbox-unselected-focus-state-layer-color: #282836;
  --mdc-checkbox-unselected-hover-state-layer-color: #282836;
  --mdc-checkbox-unselected-pressed-state-layer-color: #282836;
  --mat-option-label-text-size: 14px;
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.12);
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.26);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-select-trigger-text-line-height: 15.75px;
  --mat-mdc-icon-button-width: 34px;
  --mat-menu-item-label-text-size: 14px;
  --mdc-filled-button-disabled-label-text-color: #00000042;
  --mdc-list-list-item-focus-label-text-color: inherit;
  --mdc-list-list-item-hover-label-text-color: inherit;
  --mdc-list-list-item-label-text-color: inherit;
  --mdc-list-list-item-focus-state-layer-opacity: 0;
  --mdc-list-list-item-hover-state-layer-opacity: 0;
  --mat-text-button-pressed-state-layer-opacity: 0;
  --mat-text-button-hover-state-layer-opacity: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;
  --mat-icon-button-hover-state-layer-opacity: 0;
  --mdc-circular-progress-size: 54px;
  --mdc-circular-progress-active-indicator-width: 54px;
  --mat-badge-background-color: #b9251c;
  --mat-badge-container-offset: 0px 0px -18px -10px;
  --kendo-color-base-active: var(--dark-grey);
  --kendo-color-base-hover: var(--dark-grey);
  --kendo-color-on-primary: var(--dark-grey);
  --kendo-color-base-emphasis: var(--primary-white);
  --kendo-color-base-on-subtle: var(--primary-white);
  --kendo-color-base: var(--primary-white);
  --kendo-color-primary-subtle-active: var(--primary-white);
  --kendo-color-primary-subtle-hover: var(--primary-white);
  --kendo-color-primary-subtle: var(--primary-white);
  --kendo-color-surface: var(--primary-white);
  --kendo-color-primary-emphasis: var(--primary-yellow);
  --kendo-color-primary-hover: var(--primary-yellow);
  --kendo-color-primary-on-subtle: var(--primary-yellow);
  --kendo-color-primary-on-surface: var(--primary-yellow);
  --kendo-color-primary: var(--primary-yellow);
  --kendo-border-radius-none: 0px;
  --kendo-border-radius-md: var(--kendo-border-radius-none);
}

.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ffe600;
}

.mdc-tab {
  min-width: 160px !important;
}

.mat-mdc-tab-group.mat-primary {
  height: 100% !important;
}
.mat-mdc-tab-group.mat-primary .mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
}
.mat-mdc-tab-group.mat-primary .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mdc-tab-indicator--active {
  background: #ffe600;
  opacity: 1;
}
.mat-mdc-tab-group.mat-primary .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-ink-bar {
  background-color: #ffe600;
}
.mat-mdc-tab-group.mat-primary .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-icon-button img {
  padding: 2px;
}
.mat-mdc-tab-group.mat-primary .mat-mdc-tab-body-wrapper .mat-mdc-tab-body.mat-mdc-tab-body-active {
  overflow-y: auto;
  overflow-x: hidden;
}
.mat-mdc-tab-group.mat-primary .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content::-webkit-scrollbar-thumb {
  height: 30%;
  background-color: var(--color-primary-600);
  outline: 1px solid var(--color-primary-600);
}
.mat-mdc-tab-group.mat-primary .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content::-webkit-scrollbar {
  background-color: #dadada;
}
.mat-mdc-tab-group.mat-primary .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content::-webkit-scrollbar:vertical {
  width: 5px;
}
.mat-mdc-tab-group.mat-primary .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content::-webkit-scrollbar:horizontal {
  height: 5px;
}
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
}

.mat-mdc-tab.mdc-tab--active:focus .mdc-tab-indicator__content--underline {
  background: #ffe600 !important;
  border-color: #ffe600 !important;
}
.mat-mdc-tab .mdc-tab-indicator__content {
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1) !important;
}
.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #ffe600 !important;
}

::ng-deep .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0;
}

.form-group {
  padding-left: 0em !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: #ffffff;
  border-radius: 0;
  border: 1px solid #e1e1e6;
  box-shadow: none;
  color: #000;
  font-size: 12px;
}
.mat-mdc-tooltip.mat-mdc-tooltip--dark .mdc-tooltip__surface {
  background-color: #2e2e38;
  border-radius: 0;
  border: 1px solid #2e2e38;
  box-shadow: none;
  color: #f9f9fa;
  font-size: 12px;
}

.dcms-mat-tooltip--without-elipse {
  word-break: break-all;
}

.modal-content .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-flex {
  height: auto !important;
}
.modal-content .mat-mdc-select-arrow {
  margin-right: -4px;
}
.modal-content .mat-icon {
  font-size: 21px;
  height: 21px;
  width: 21px;
  line-height: 21px;
}
.modal-content .mdc-list-item {
  background-color: #ffffff;
  margin: 0 16px 8px;
  position: relative;
  height: inherit;
}
.modal-content .mat-mdc-list-option {
  padding: 0 16px !important;
}
.modal-content .mat-mdc-list-item {
  height: auto !important;
  min-height: 48px !important;
  width: calc(100% - 32px) !important;
}
.modal-content .mdc-list-item__start {
  margin-left: 0 !important;
}
.modal-content .mat-mdc-list-option .mdc-checkbox__background {
  width: 16px !important;
  height: 16px !important;
}
.modal-content .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin: 16px !important;
}
.modal-content .modal-footer {
  display: flex;
  gap: 15px;
}
.modal-content .modal-footer .mdc-button {
  margin: 0;
}
.modal-content .mat-mdc-list-item:focus,
.modal-content .mat-mdc-list-item:active,
.modal-content .mat-mdc-list-item:hover {
  background-color: #ffffff !important;
  opacity: 1 !important;
}
.modal-content .mat-mdc-slide-toggle {
  margin-bottom: 10px;
}

app-data-grid app-list-item button {
  line-height: 21px !important;
}

app-page-header .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  min-height: 44px !important;
}

app-list-item {
  padding: 6px 8px 6px 0 !important;
  background-color: #fff;
}

.search-list-item__container .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.search-list-item__container .mat-mdc-text-field-wrapper {
  background-color: #ffffff !important;
}

.mat-select-search-inside-mat-option .mat-select-search-clear {
  margin-right: 10.5px;
}

.mat-select-search-clear {
  right: 0 !important;
  height: 42px !important;
  top: 0 !important;
}
.mat-select-search-clear .mat-icon {
  margin-right: 0 !important;
}

.mat-select-search-inner {
  border-width: 0 !important;
}
.mat-select-search-inner .mat-mdc-icon-button.mat-mdc-button-base {
  width: var(--mat-mdc-icon-button-width) !important;
}

.mat-mdc-option.contains-mat-select-search {
  padding-right: 0 !important;
  border-bottom: 1px solid #e0e0e0 !important;
  background-color: #ffffff !important;
  position: sticky;
  top: 0;
  z-index: 1000;
  cursor: pointer !important;
  pointer-events: all !important;
  opacity: 1;
}
.mat-mdc-option.contains-mat-select-search .mat-select-search-input {
  padding-left: 0;
}

.mat-mdc-option.mdc-list-item.mat-select-search-no-entries-found {
  padding: 0;
}

.mat-select-search-no-entries-found .mdc-list-item__primary-text .mat-select-search-input {
  border-bottom: 1px solid #e0e0e0;
  padding-left: 16px;
}
.mat-select-search-no-entries-found .mdc-list-item__primary-text .mat-select-search-no-entries-found {
  padding: 0 0 0 16px !important;
  color: rgba(0, 0, 0, 0.3803921569) !important;
}

.search-input {
  height: 2.75rem;
  background-color: #f6f6fa;
  border-radius: 0;
  border: none;
  border-bottom: 0.163rem solid #dadada;
  font-family: EYInterstate;
  font-size: 0.875rem !important;
  color: #2e2e38;
  font-weight: 300 !important;
  font-style: normal;
}

.mat-mdc-input-element {
  text-overflow: ellipsis;
  border: none;
  background: #f6f6fa;
  font-family: EYInterstate;
  font-size: 14px !important;
  color: #2e2e38;
  font-weight: 400 !important;
  font-style: normal;
  height: 1.875em !important;
}

app-search-item-list .common-item-list__subline .mat-icon {
  font-size: 15px;
  height: 15px;
  width: 15px;
  line-height: 15px;
}
app-search-item-list .search-list-item__container {
  width: 673.5px;
}
app-search-item-list .common-item-list__item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 20px;
}
app-search-item-list .common-item-list__item p {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  width: 100%;
}
app-search-item-list .selection-item-list__item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
app-search-item-list .selection-item-list__item p {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  width: 100%;
}
app-search-item-list p {
  text-align: left !important;
}

.dcms-search-list-dialog.modal-dialog {
  --bs-modal-width: 712px;
}
.dcms-search-list-dialog.modal-dialog .modal-body {
  max-height: 100%;
  overflow: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.dcms-search-list-dialog.modal-dialog .controls-container {
  padding-top: 2rem;
  height: unset;
}
.dcms-search-list-dialog.modal-dialog .controls-container,
.dcms-search-list-dialog.modal-dialog .buttons-container {
  padding-left: 2rem;
  padding-right: 2rem;
}
.dcms-search-list-dialog.modal-dialog .buttons-container {
  margin-top: auto;
  flex-shrink: 0;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.dcms-search-list-dialog.modal-dialog .modal-content:has(> .buttons-container) .modal-body {
  padding-bottom: 0;
}
.dcms-search-list-dialog.modal-dialog .modal-footer {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex-shrink: 0;
  gap: 15px;
  margin-top: auto;
  padding: 2rem 2rem 2rem 2rem;
}

.dcms-side-dialog.modal-dialog {
  --bs-modal-width: 772px;
}
.dcms-side-dialog.modal-dialog .modal-body {
  max-height: 100%;
  overflow: auto;
  display: flex;
  padding: 0;
  flex-direction: column;
}
.dcms-side-dialog.modal-dialog .modal-body::-webkit-scrollbar-thumb {
  height: 30%;
  background-color: var(--color-primary-600);
  outline: 1px solid var(--color-primary-600);
}
.dcms-side-dialog.modal-dialog .modal-body::-webkit-scrollbar {
  background-color: #dadada;
}
.dcms-side-dialog.modal-dialog .modal-body::-webkit-scrollbar:vertical {
  width: 5px;
}
.dcms-side-dialog.modal-dialog .modal-body::-webkit-scrollbar:horizontal {
  height: 5px;
}
.dcms-side-dialog.modal-dialog .modal-body .material-icons {
  font-size: 21px;
}
.dcms-side-dialog.modal-dialog .modal-body .mat-icon {
  height: 21px;
  width: 21px;
}
.dcms-side-dialog.modal-dialog .modal-body .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 28px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 0;
}
.dcms-side-dialog.modal-dialog .controls-container {
  padding-top: 60px;
  height: unset;
}
.dcms-side-dialog.modal-dialog .controls-container,
.dcms-side-dialog.modal-dialog .buttons-container {
  padding-left: 80px;
  padding-right: 80px;
}
.dcms-side-dialog.modal-dialog .buttons-container {
  margin-top: auto;
  flex-shrink: 0;
  padding-bottom: 60px;
  padding-top: 30px;
}
.dcms-side-dialog.modal-dialog .modal-content:has(> .buttons-container) .modal-body {
  padding-bottom: 0;
}
.dcms-side-dialog.modal-dialog .modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  border: none;
  flex-shrink: 0;
  gap: 15px;
  margin-top: auto;
  padding: 2rem 5rem 4rem 5rem;
}

.modal-static.modal > .dcms-side-dialog {
  transform: none;
}

.tab-header-list-container .mat-icon {
  font-size: 1.2rem !important;
  height: 18px !important;
  width: 20px !important;
  line-height: 1.2rem !important;
  margin-right: 0 !important;
}
.tab-header-list-container .mdc-button {
  min-width: 64px !important;
}
.tab-header-list-container .mdc-button__label {
  margin-left: 3px;
}

:root {
  --font-size-title: 1.2142857143rem;
  --font-size-title-h6: 1.1428571429rem;
  --font-size-small: 0.8571428571rem;
  --font-size-medium: 1rem;
  --dark-grey: #2e2e38;
  --primary-white: #ffffff;
  --primary-yellow: #ffe600;
  --color-light-gray: #dadada;
  --color-blue: #155cb4;
  --mat-tab-header-active-focus-indicator-color: var(--primary-yellow);
  --mdc-tab-indicator-active-indicator-color: var(--primary-yellow);
  --mat-icon-button-state-layer-color: transparent;
  --mat-menu-item-label-text-weight: 300;
  --mat-form-field-container-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-container-shape: 0;
  --mat-filled-button-icon-spacing: 3px;
  --mat-filled-button-icon-offset: 0;
  --mat-form-field-container-text-line-height: 15.75px;
  --mdc-filled-text-field-label-text-color: #000000de;
  --mdc-filled-text-field-container-color: #f6f6fa;
  --mdc-filled-text-field-disabled-container-color: #f6f6fa;
  --mat-mdc-form-field-flex-height: 51px;
  --mat-form-field-container-height: 52px;
  --mat-form-field-filled-with-label-container-padding-top: 20px;
  --mat-select-arrow-transform: translateY(-7px) translateX(-4px);
  --mdc-filled-text-field-error-label-text-color: inherit;
  --mat-form-field-state-layer-color: inherit;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-subscript-text-line-height: 0;
  --mat-form-field-container-vertical-padding: 8px;
  --mdc-checkbox-state-layer-size: 0;
  --mat-expansion-container-text-line-height: 16px;
  --mdc-checkbox-unselected-icon-color: #282836;
  --mdc-checkbox-unselected-focus-state-layer-color: #282836;
  --mdc-checkbox-unselected-hover-state-layer-color: #282836;
  --mdc-checkbox-unselected-pressed-state-layer-color: #282836;
  --mat-option-label-text-size: 14px;
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.12);
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.26);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-select-trigger-text-line-height: 15.75px;
  --mat-mdc-icon-button-width: 34px;
  --mat-menu-item-label-text-size: 14px;
  --mdc-filled-button-disabled-label-text-color: #00000042;
  --mdc-list-list-item-focus-label-text-color: inherit;
  --mdc-list-list-item-hover-label-text-color: inherit;
  --mdc-list-list-item-label-text-color: inherit;
  --mdc-list-list-item-focus-state-layer-opacity: 0;
  --mdc-list-list-item-hover-state-layer-opacity: 0;
  --mat-text-button-pressed-state-layer-opacity: 0;
  --mat-text-button-hover-state-layer-opacity: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;
  --mat-icon-button-hover-state-layer-opacity: 0;
  --mdc-circular-progress-size: 54px;
  --mdc-circular-progress-active-indicator-width: 54px;
  --mat-badge-background-color: #b9251c;
  --mat-badge-container-offset: 0px 0px -18px -10px;
  --kendo-color-base-active: var(--dark-grey);
  --kendo-color-base-hover: var(--dark-grey);
  --kendo-color-on-primary: var(--dark-grey);
  --kendo-color-base-emphasis: var(--primary-white);
  --kendo-color-base-on-subtle: var(--primary-white);
  --kendo-color-base: var(--primary-white);
  --kendo-color-primary-subtle-active: var(--primary-white);
  --kendo-color-primary-subtle-hover: var(--primary-white);
  --kendo-color-primary-subtle: var(--primary-white);
  --kendo-color-surface: var(--primary-white);
  --kendo-color-primary-emphasis: var(--primary-yellow);
  --kendo-color-primary-hover: var(--primary-yellow);
  --kendo-color-primary-on-subtle: var(--primary-yellow);
  --kendo-color-primary-on-surface: var(--primary-yellow);
  --kendo-color-primary: var(--primary-yellow);
  --kendo-border-radius-none: 0px;
  --kendo-border-radius-md: var(--kendo-border-radius-none);
}

:root {
  --font-size-title: 1.2142857143rem;
  --font-size-title-h6: 1.1428571429rem;
  --font-size-small: 0.8571428571rem;
  --font-size-medium: 1rem;
  --dark-grey: #2e2e38;
  --primary-white: #ffffff;
  --primary-yellow: #ffe600;
  --color-light-gray: #dadada;
  --color-blue: #155cb4;
  --mat-tab-header-active-focus-indicator-color: var(--primary-yellow);
  --mdc-tab-indicator-active-indicator-color: var(--primary-yellow);
  --mat-icon-button-state-layer-color: transparent;
  --mat-menu-item-label-text-weight: 300;
  --mat-form-field-container-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-container-shape: 0;
  --mat-filled-button-icon-spacing: 3px;
  --mat-filled-button-icon-offset: 0;
  --mat-form-field-container-text-line-height: 15.75px;
  --mdc-filled-text-field-label-text-color: #000000de;
  --mdc-filled-text-field-container-color: #f6f6fa;
  --mdc-filled-text-field-disabled-container-color: #f6f6fa;
  --mat-mdc-form-field-flex-height: 51px;
  --mat-form-field-container-height: 52px;
  --mat-form-field-filled-with-label-container-padding-top: 20px;
  --mat-select-arrow-transform: translateY(-7px) translateX(-4px);
  --mdc-filled-text-field-error-label-text-color: inherit;
  --mat-form-field-state-layer-color: inherit;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-subscript-text-line-height: 0;
  --mat-form-field-container-vertical-padding: 8px;
  --mdc-checkbox-state-layer-size: 0;
  --mat-expansion-container-text-line-height: 16px;
  --mdc-checkbox-unselected-icon-color: #282836;
  --mdc-checkbox-unselected-focus-state-layer-color: #282836;
  --mdc-checkbox-unselected-hover-state-layer-color: #282836;
  --mdc-checkbox-unselected-pressed-state-layer-color: #282836;
  --mat-option-label-text-size: 14px;
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.12);
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.26);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-select-trigger-text-line-height: 15.75px;
  --mat-mdc-icon-button-width: 34px;
  --mat-menu-item-label-text-size: 14px;
  --mdc-filled-button-disabled-label-text-color: #00000042;
  --mdc-list-list-item-focus-label-text-color: inherit;
  --mdc-list-list-item-hover-label-text-color: inherit;
  --mdc-list-list-item-label-text-color: inherit;
  --mdc-list-list-item-focus-state-layer-opacity: 0;
  --mdc-list-list-item-hover-state-layer-opacity: 0;
  --mat-text-button-pressed-state-layer-opacity: 0;
  --mat-text-button-hover-state-layer-opacity: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;
  --mat-icon-button-hover-state-layer-opacity: 0;
  --mdc-circular-progress-size: 54px;
  --mdc-circular-progress-active-indicator-width: 54px;
  --mat-badge-background-color: #b9251c;
  --mat-badge-container-offset: 0px 0px -18px -10px;
  --kendo-color-base-active: var(--dark-grey);
  --kendo-color-base-hover: var(--dark-grey);
  --kendo-color-on-primary: var(--dark-grey);
  --kendo-color-base-emphasis: var(--primary-white);
  --kendo-color-base-on-subtle: var(--primary-white);
  --kendo-color-base: var(--primary-white);
  --kendo-color-primary-subtle-active: var(--primary-white);
  --kendo-color-primary-subtle-hover: var(--primary-white);
  --kendo-color-primary-subtle: var(--primary-white);
  --kendo-color-surface: var(--primary-white);
  --kendo-color-primary-emphasis: var(--primary-yellow);
  --kendo-color-primary-hover: var(--primary-yellow);
  --kendo-color-primary-on-subtle: var(--primary-yellow);
  --kendo-color-primary-on-surface: var(--primary-yellow);
  --kendo-color-primary: var(--primary-yellow);
  --kendo-border-radius-none: 0px;
  --kendo-border-radius-md: var(--kendo-border-radius-none);
}

.k-button {
  font-size: 14px;
}

.k-button-solid-base:hover,
.k-button-solid-base.k-hover {
  background-color: var(--color-primary-50);
}

.k-button-solid-primary:active,
.k-button-solid-primary.k-active {
  border-color: #ffe600;
  background-color: #ffe600;
}

.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
  box-shadow: unset;
}

.k-list-item:hover,
.k-list-optionlabel:hover,
.k-list-item.k-hover,
.k-hover.k-list-optionlabel {
  background-color: rgba(0, 0, 0, 0.0392156863);
}

.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  background-color: rgba(0, 0, 0, 0.0392156863);
}

.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
  background-color: rgba(0, 0, 0, 0.0392156863);
}

.k-list-content::-webkit-scrollbar-thumb {
  height: 30%;
  background-color: var(--color-primary-600);
  outline: 1px solid var(--color-primary-600);
}
.k-list-content::-webkit-scrollbar {
  background-color: #dadada;
}
.k-list-content::-webkit-scrollbar:vertical {
  width: 5px;
}
.k-list-content::-webkit-scrollbar:horizontal {
  height: 5px;
}

.k-button-flat-primary {
  color: var(--mdc-list-list-item-label-text-color);
  font-weight: 600;
}

.k-calendar .k-calendar-td:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
  background-color: var(--color-primary-300);
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
  background-color: rgba(46, 46, 56, 0.2);
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
  background-color: #23232f;
  color: #ffffff;
}

.k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
  background-color: #23232f;
  color: #ffffff;
}

.k-calendar .k-calendar-view .k-today {
  color: unset;
}

.k-option-inactive {
  opacity: 0.5;
}

:host {
  display: flex;
}

.toast-container .ngx-toastr {
  display: flex;
  box-shadow: unset;
  border-radius: unset;
  padding: unset;
  min-width: 400px;
  background-color: #ffffff;
  color: #23232f;
}
.toast-container .ngx-toastr:hover {
  box-shadow: unset;
}
.toast-container .toast-error,
.toast-container .toast-success,
.toast-container .toast-warning {
  background-image: unset;
}
.toast-container .mat-mdc-button {
  padding: unset;
}
.toast-container .mat-icon {
  display: table-cell;
}
.toast-container__toast-description {
  margin-left: 0;
}
.toast-container__toast-message {
  margin-top: 2vh;
}
.toast-container__header {
  width: 15%;
  display: table;
}
.toast-container__header-success {
  background-color: #168736;
}
.toast-container__header-error {
  background-color: #b9251c;
}
.toast-container__header-warning {
  background-color: #ffe600;
}
.toast-container__header-icon {
  color: #ffffff;
  vertical-align: middle;
  text-align: center;
}
.toast-container__content {
  width: 75%;
  flex: 1;
  word-break: break-word;
  padding: 10px;
}
.toast-container__close {
  width: 10%;
  display: table;
}
.toast-container__close-wrapper {
  vertical-align: top;
  text-align: center;
  display: table-cell;
}
.toast-container__close-wrapper .mat-mdc-icon-button {
  margin-top: 0.4rem;
}
.toast-container__button-link {
  color: #155cb4;
}
.toast-container__button-link:not(.mat-mdc-button-disabled) {
  color: var(--color-blue);
}
.toast-container .toast-err-msg {
  font-size: 0.8rem;
}

@font-face {
  font-family: "EYInterstate";
  src: url("../fonts/EYInterstate-Regular.eot");
  src: local("EYInterstate-Regular"), url("../fonts/EYInterstate-Regular.woff2") format("woff2"), url("../fonts/EYInterstate-Regular.ttf") format("truetype"), url("../fonts/EYInterstate-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "EYInterstate";
  src: url("../fonts/EYInterstate-Light.eot");
  src: local("EYInterstate-Light"), url("../fonts/EYInterstate-Light.woff2") format("woff2"), url("../fonts/EYInterstate-Light.ttf") format("truetype"), url("../fonts/EYInterstate-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "EYInterstate";
  src: url("../fonts/EYInterstate-Bold.eot");
  src: local("EYInterstate-Bold"), url("../fonts/EYInterstate-Bold.woff2") format("woff2"), url("../fonts/EYInterstate-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}