.tab-header-list-container {
  & .mat-icon {
    font-size: 1.2rem !important;
    height: 18px !important;
    width: 20px !important;
    line-height: 1.2rem !important;
    margin-right: 0 !important;
  }

  & .mdc-button {
    min-width: 64px !important;
  }

  & .mdc-button__label {
    margin-left: 3px;
  }
}
