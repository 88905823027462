@mixin font-properties($size, $color, $font-family, $font-weight) {
  font-family: $font-family;
  font-size: $size !important;
  color: $color;
  font-weight: $font-weight !important;
  font-style: normal;
}

@mixin material-icon($icon, $size, $color, $font-weight) {
  @include font-properties($size, $color, 'Material Icons', $font-weight);

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: $icon;
  font-feature-settings: 'liga';
  letter-spacing: normal;
  line-height: 1;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin md-icon($size, $color) {
  font-size: $size;
  height: $size;
  width: $size;
  color: $color;
}

@mixin icon-size($size) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

@mixin dcms-scroll() {
  &::-webkit-scrollbar-thumb {
    height: 30%;
    background-color: var(--color-primary-600);
    outline: 1px solid var(--color-primary-600);
  }

  &::-webkit-scrollbar {
    background-color: $lighter-grey;

    &:vertical {
      width: 5px;
    }

    &:horizontal {
      height: 5px;
    }
  }
}

@mixin display-flex($flex-direction, $align-items, $justify-content) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
}
