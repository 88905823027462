@use '@angular/material' as mat;

$my-custom-typography-config: mat.m2-define-typography-config(
  $font-family: EYInterstate,
  $headline-1: mat.m2-define-typography-level(2.285rem, 2.857rem, 700),
  $headline-2: mat.m2-define-typography-level(2rem, 2.285rem, 700),
  $headline-3: mat.m2-define-typography-level(1.714rem, 2rem, 700),
  $headline-4: mat.m2-define-typography-level(1.428rem, 1.714rem, 700),
  $headline-5: mat.m2-define-typography-level(1.142rem, 1.28rem, 700),
  $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
  $body-1: mat.m2-define-typography-level(15px, 24px, 400),
  $subtitle-2: mat.m2-define-typography-level(14px, 24px, 500),
  $body-2: mat.m2-define-typography-level(14px, 20px, 400),
  $caption: mat.m2-define-typography-level(0.857rem, 1.142rem, lighter),
  $button: mat.m2-define-typography-level(1rem, 1rem, 700),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($my-custom-typography-config);`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies($my-custom-typography-config);
@include mat.all-component-typographies($my-custom-typography-config);
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();
@include mat.core();

$dcms-primary: (
  50: #e1e1e6,
  100: #f6f6fa,
  200: #eaeaf2,
  300: #c4c4cd,
  400: #747480,
  500: #2e2e38,
  600: #23232f,
  700: #23232b,
  800: #1d1d24,
  900: #282836,
  A100: #5d5dff,
  A200: #2a2aff,
  A400: #0000f6,
  A700: #0000dd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$dcms-accent: (
  50: #fffce0,
  100: #fff8b3,
  200: #fff380,
  300: #ffee4d,
  400: #ffea26,
  500: #ffe600,
  600: #ffe300,
  700: #ffdf00,
  800: #ffdb00,
  900: #ffd500,
  A100: #ffffff,
  A200: #ffe600,
  A400: #fff3bf,
  A700: #ffeea6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.m2-define-palette($dcms-primary, 500);
$my-accent: mat.m2-define-palette($dcms-accent, A200, A100, A400);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: $my-custom-typography-config,
    density: 0,
  )
);

@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);

:root {
  --color-primary: #{mat.m2-get-color-from-palette($dcms-primary, 500)};
  --color-primary-50: #{mat.m2-get-color-from-palette($dcms-primary, 50)};
  --color-primary-100: #{mat.m2-get-color-from-palette($dcms-primary, 100)};
  --color-primary-200: #{mat.m2-get-color-from-palette($dcms-primary, 200)};
  --color-primary-300: #{mat.m2-get-color-from-palette($dcms-primary, 300)};
  --color-primary-400: #{mat.m2-get-color-from-palette($dcms-primary, 400)};
  --color-primary-500: #{mat.m2-get-color-from-palette($dcms-primary, 500)};
  --color-primary-600: #{mat.m2-get-color-from-palette($dcms-primary, 600)};
  --color-primary-900: #{mat.m2-get-color-from-palette($dcms-primary, 900)};
  --color-accent: #{mat.m2-get-color-from-palette($dcms-accent, 500)};
  --color-warn: #b9251c;
  --color-white: #fff;
  --spacing-small: 10px;
  --spacing-default: 12px;
  --spacing-big: 15px;
}
