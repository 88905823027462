@import '../shared/mixins';

.close-icon {
  color: $primary-white !important;
}

.modal-header {
  background: $modal-header-background-color !important;
  border-radius: 0;
  height: 6vh;
  padding: 16px 14px 16px 20px;
  justify-content: space-between;

  .modal-title {
    @include font-properties(20px, $header-font-color, $ey-font-family, 400);
  }

  .close {
    background: none;
    border: none;
  }
}

.modal {
  --bs-modal-width: 39.5em;
  :has(.confirmation-dialog) {
    --bs-modal-width: 50em;
  }
  --bs-modal-margin: none !important;
}

.modal-dialog:not(.modal-dialog:has(.bulkupload-modal, .confirmation-dialog, .search-list-dialog-modal)) {
  margin-right: 0 !important;
}

.modal-content {
  border-radius: 0;
  width: 100%;
  border: none;
  margin-top: 0 !important;

  &:not(:has(.bulkupload-modal, .confirmation-dialog, .search-list-dialog-modal)) {
    margin-top: 0 !important;
    height: 100vh !important;
  }

  &:has(.confirmation-modal) {
    margin-top: 45% !important;
    width: 450px;
  }

  &:has(.confirmation-dialog) {
    top: 30vh;
  }

  &:has(.search-list-dialog-modal) {
    top: 20vh;
  }
}

.modal-footer {
  justify-content: left;
  border: none;
}
