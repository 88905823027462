:root {
  --font-size-title: 1.2142857143rem;
  --font-size-title-h6: 1.1428571429rem;
  --font-size-small: 0.8571428571rem;
  --font-size-medium: 1rem;
  --dark-grey: #2e2e38;
  --primary-white: #ffffff;
  --primary-yellow: #ffe600;
  --color-light-gray: #dadada;
  --color-blue: #155cb4;
  --mat-tab-header-active-focus-indicator-color: var(--primary-yellow);
  --mdc-tab-indicator-active-indicator-color: var(--primary-yellow);
  --mat-icon-button-state-layer-color: transparent;
  --mat-menu-item-label-text-weight: 300;
  --mat-form-field-container-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-container-shape: 0;
  --mat-filled-button-icon-spacing: 3px;
  --mat-filled-button-icon-offset: 0;
  --mat-form-field-container-text-line-height: 15.75px;
  --mdc-filled-text-field-label-text-color: #000000de;
  --mdc-filled-text-field-container-color: #f6f6fa;
  --mdc-filled-text-field-disabled-container-color: #f6f6fa;
  --mat-mdc-form-field-flex-height: 51px;
  --mat-form-field-container-height: 52px;
  --mat-form-field-filled-with-label-container-padding-top: 20px;
  --mat-select-arrow-transform: translateY(-7px) translateX(-4px);
  --mdc-filled-text-field-error-label-text-color: inherit;
  --mat-form-field-state-layer-color: inherit;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-subscript-text-line-height: 0;
  --mat-form-field-container-vertical-padding: 8px;
  --mdc-checkbox-state-layer-size: 0;
  --mat-expansion-container-text-line-height: 16px;
  --mdc-checkbox-unselected-icon-color: #282836;
  --mdc-checkbox-unselected-focus-state-layer-color: #282836;
  --mdc-checkbox-unselected-hover-state-layer-color: #282836;
  --mdc-checkbox-unselected-pressed-state-layer-color: #282836;
  --mat-option-label-text-size: 14px;
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.12);
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.26);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-select-trigger-text-line-height: 15.75px;
  --mat-mdc-icon-button-width: 34px;
  --mat-menu-item-label-text-size: 14px;
  --mdc-filled-button-disabled-label-text-color: #00000042;
  --mdc-list-list-item-focus-label-text-color: inherit;
  --mdc-list-list-item-hover-label-text-color: inherit;
  --mdc-list-list-item-label-text-color: inherit;
  --mdc-list-list-item-focus-state-layer-opacity: 0;
  --mdc-list-list-item-hover-state-layer-opacity: 0;
  --mat-text-button-pressed-state-layer-opacity: 0;
  --mat-text-button-hover-state-layer-opacity: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;
  --mat-icon-button-hover-state-layer-opacity: 0;
  --mdc-circular-progress-size: 54px;
  --mdc-circular-progress-active-indicator-width: 54px;
  --mat-badge-background-color: #b9251c;
  --mat-badge-container-offset: 0px 0px -18px -10px;
  --kendo-color-base-active: var(--dark-grey);
  --kendo-color-base-hover: var(--dark-grey);
  --kendo-color-on-primary: var(--dark-grey);
  --kendo-color-base-emphasis: var(--primary-white);
  --kendo-color-base-on-subtle: var(--primary-white);
  --kendo-color-base: var(--primary-white);
  --kendo-color-primary-subtle-active: var(--primary-white);
  --kendo-color-primary-subtle-hover: var(--primary-white);
  --kendo-color-primary-subtle: var(--primary-white);
  --kendo-color-surface: var(--primary-white);
  --kendo-color-primary-emphasis: var(--primary-yellow);
  --kendo-color-primary-hover: var(--primary-yellow);
  --kendo-color-primary-on-subtle: var(--primary-yellow);
  --kendo-color-primary-on-surface: var(--primary-yellow);
  --kendo-color-primary: var(--primary-yellow);
  --kendo-border-radius-none: 0px;
  --kendo-border-radius-md: var(--kendo-border-radius-none);
}

.k-button {
  font-size: 14px;
}

.k-button-solid-base:hover,
.k-button-solid-base.k-hover {
  background-color: var(--color-primary-50);
}

.k-button-solid-primary:active,
.k-button-solid-primary.k-active {
  border-color: #ffe600;
  background-color: #ffe600;
}

.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
  box-shadow: unset;
}

.k-list-item:hover,
.k-list-optionlabel:hover,
.k-list-item.k-hover,
.k-hover.k-list-optionlabel {
  background-color: rgba(0, 0, 0, 0.0392156863);
}

.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  background-color: rgba(0, 0, 0, 0.0392156863);
}

.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
  background-color: rgba(0, 0, 0, 0.0392156863);
}

.k-list-content::-webkit-scrollbar-thumb {
  height: 30%;
  background-color: var(--color-primary-600);
  outline: 1px solid var(--color-primary-600);
}
.k-list-content::-webkit-scrollbar {
  background-color: #dadada;
}
.k-list-content::-webkit-scrollbar:vertical {
  width: 5px;
}
.k-list-content::-webkit-scrollbar:horizontal {
  height: 5px;
}

.k-button-flat-primary {
  color: var(--mdc-list-list-item-label-text-color);
  font-weight: 600;
}

.k-calendar .k-calendar-td:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
  background-color: var(--color-primary-300);
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
  background-color: rgba(46, 46, 56, 0.2);
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
  background-color: #23232f;
  color: #ffffff;
}

.k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
  background-color: #23232f;
  color: #ffffff;
}

.k-calendar .k-calendar-view .k-today {
  color: unset;
}

.k-option-inactive {
  opacity: 0.5;
}