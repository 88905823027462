@font-face {
  font-family: 'EYInterstate';
  src: url('../fonts/EYInterstate-Regular.eot');
  src: local('EYInterstate-Regular'), url('../fonts/EYInterstate-Regular.woff2') format('woff2'),
    url('../fonts/EYInterstate-Regular.ttf') format('truetype'), url('../fonts/EYInterstate-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'EYInterstate';
  src: url('../fonts/EYInterstate-Light.eot');
  src: local('EYInterstate-Light'), url('../fonts/EYInterstate-Light.woff2') format('woff2'),
    url('../fonts/EYInterstate-Light.ttf') format('truetype'), url('../fonts/EYInterstate-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'EYInterstate';
  src: url('../fonts/EYInterstate-Bold.eot');
  src: local('EYInterstate-Bold'), url('../fonts/EYInterstate-Bold.woff2') format('woff2'), url('../fonts/EYInterstate-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
