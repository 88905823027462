$tooltip-border-light: 1px solid #e1e1e6;
$tooltip-background-light: $primary-white;
$tooltip-color-light: #000;

$tooltip-border-dark: 1px solid $background-color;
$tooltip-background-dark: $background-color;
$tooltip-color-dark: #f9f9fa;

@mixin tooltip-surface($border, $background, $color) {
  background-color: $background;
  border-radius: 0;
  border: $border;
  box-shadow: none;
  color: $color;
  font-size: 12px;
}

.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    @include tooltip-surface($tooltip-border-light, $tooltip-background-light, $tooltip-color-light);
  }

  &#{&}--dark {
    .mdc-tooltip__surface {
      @include tooltip-surface($tooltip-border-dark, $tooltip-background-dark, $tooltip-color-dark);
    }
  }
}

.dcms-mat-tooltip--without-elipse {
  word-break: break-all;
}
