.filter-label {
  margin-left: 1.3rem;
  margin-top: 1vh;
}

.filter-grid {
  border: 1px solid var(--color-primary-50);
  max-height: 100%;
  border-top: none;
  border-right: none;
}

.hr-divider {
  background: $primary-white;
  border-top: 0.188rem solid $hr-divider-color;
}
