app-data-grid app-list-item button {
  line-height: 21px !important;
}

app-page-header {
  & .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    min-height: 44px !important;
  }
}

app-list-item {
  padding: 6px 8px 6px 0 !important;
  background-color: #fff;
}
