@import '../shared/variables.scss';
@import '../../../styles.scss';

:host {
  display: flex;
}

.toast-container {
  .ngx-toastr {
    display: flex;
    box-shadow: unset;
    border-radius: unset;
    padding: unset;
    min-width: 400px;
    background-color: $primary-white;
    color: $primary-black;
  }
  .ngx-toastr:hover {
    box-shadow: unset;
  }

  .toast-error,
  .toast-success,
  .toast-warning {
    background-image: unset;
  }

  .mat-mdc-button {
    padding: unset;
  }
  .mat-icon {
    display: table-cell;
  }

  &__toast-title {
    @extend .mat-caption !optional;
  }

  &__toast-description {
    margin-left: 0;
  }

  &__toast-message {
    margin-top: 2vh;
  }

  &__header {
    width: 15%;
    display: table;

    &-success {
      background-color: $primary-green;
    }

    &-error {
      background-color: $primary-red;
    }

    &-warning {
      background-color: $primary-yellow;
    }

    &-icon {
      color: $primary-white;
      vertical-align: middle;
      text-align: center;
    }
  }

  &__content {
    width: 75%;
    flex: 1;
    word-break: break-word;
    padding: 10px;
  }
  &__close {
    width: 10%;
    display: table;
    &-wrapper {
      vertical-align: top;
      text-align: center;
      display: table-cell;
      .mat-mdc-icon-button {
        margin-top: 0.4rem;
      }
    }
  }

  &__button-link {
    color: $blue;
    &:not(.mat-mdc-button-disabled) {
      color: var(--color-blue);
    }
  }
  .toast-err-msg {
    font-size: 0.8rem;
  }
}
