.mat-mdc-select-panel {
  border-radius: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  box-shadow: var(--mat-select-container-elevation-shadow);

  &:focus {
    box-shadow: var(--mat-select-container-elevation-shadow) !important;
  }
}

.mat-mdc-select {
  .mat-mdc-select-min-line {
    white-space: normal;
  }
  .mat-mdc-select-trigger {
    height: auto;
  }
  .mat-mdc-select-arrow-wrapper {
    margin-bottom: auto;
    margin-left: 7px;

    & .mat-mdc-select-arrow {
      border-left: 15px solid transparent !important;
      border-right: none !important;
      border-top: 15px solid transparent !important;
      border-image-source: url('../../../assets/images/down_arrow.png') !important;
      border-image-repeat: stretch !important;

      svg {
        display: none;
      }
    }
  }
}

.mat-select-search-input {
  border: none;
}

.mat-mdc-select-value {
  margin-top: 3px;
  padding-top: 3px;
}
