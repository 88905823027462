$ey-font-family: EYInterstate;

:root {
  --font-size-title: #{calc(17 / 14)}rem;
  --font-size-title-h6: #{calc(16 / 14)}rem;
  --font-size-small: #{calc(12 / 14)}rem;
  --font-size-medium: 1rem;
  --dark-grey: #2e2e38;
  --primary-white: #ffffff;
  --primary-yellow: #ffe600;
  --color-light-gray: #dadada;
  --color-blue: #155cb4;
  --mat-tab-header-active-focus-indicator-color: var(--primary-yellow);
  --mdc-tab-indicator-active-indicator-color: var(--primary-yellow);
  --mat-icon-button-state-layer-color: transparent;
  --mat-menu-item-label-text-weight: 300;

  --mat-form-field-container-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-container-shape: 0;
  --mat-filled-button-icon-spacing: 3px;
  --mat-filled-button-icon-offset: 0;
  --mat-form-field-container-text-line-height: 15.75px;
  --mdc-filled-text-field-label-text-color: #000000de;
  --mdc-filled-text-field-container-color: #f6f6fa;
  --mdc-filled-text-field-disabled-container-color: #f6f6fa;

  --mat-mdc-form-field-flex-height: 51px;
  --mat-form-field-container-height: 52px;
  --mat-form-field-filled-with-label-container-padding-top: 20px;
  --mat-select-arrow-transform: translateY(-7px) translateX(-4px);
  --mdc-filled-text-field-error-label-text-color: inherit;
  --mat-form-field-state-layer-color: inherit;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-subscript-text-line-height: 0;
  --mat-form-field-container-vertical-padding: 8px;

  --mdc-checkbox-state-layer-size: 0;
  --mat-expansion-container-text-line-height: 16px;
  --mdc-checkbox-unselected-icon-color: #282836;
  --mdc-checkbox-unselected-focus-state-layer-color: #282836;
  --mdc-checkbox-unselected-hover-state-layer-color: #282836;
  --mdc-checkbox-unselected-pressed-state-layer-color: #282836;
  --mat-option-label-text-size: 14px;
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.12);
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.26);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-select-trigger-text-line-height: 15.75px;
  --mat-mdc-icon-button-width: 34px;
  --mat-menu-item-label-text-size: 14px;
  --mdc-filled-button-disabled-label-text-color: #00000042;
  --mdc-list-list-item-focus-label-text-color: inherit;
  --mdc-list-list-item-hover-label-text-color: inherit;
  --mdc-list-list-item-label-text-color: inherit;
  --mdc-list-list-item-focus-state-layer-opacity: 0;
  --mdc-list-list-item-hover-state-layer-opacity: 0;
  --mat-text-button-pressed-state-layer-opacity: 0;
  --mat-text-button-hover-state-layer-opacity: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;
  --mat-icon-button-hover-state-layer-opacity: 0;
  --mdc-circular-progress-size: 54px;
  --mdc-circular-progress-active-indicator-width: 54px;
  --mat-badge-background-color: #b9251c;
  --mat-badge-container-offset: 0px 0px -18px -10px;

  --kendo-color-base-active: var(--dark-grey);
  --kendo-color-base-hover: var(--dark-grey);
  --kendo-color-on-primary: var(--dark-grey);
  --kendo-color-base-emphasis: var(--primary-white);
  --kendo-color-base-on-subtle: var(--primary-white);
  --kendo-color-base: var(--primary-white);
  --kendo-color-primary-subtle-active: var(--primary-white);
  --kendo-color-primary-subtle-hover: var(--primary-white);
  --kendo-color-primary-subtle: var(--primary-white);
  --kendo-color-surface: var(--primary-white);
  --kendo-color-primary-emphasis: var(--primary-yellow);
  --kendo-color-primary-hover: var(--primary-yellow);
  --kendo-color-primary-on-subtle: var(--primary-yellow);
  --kendo-color-primary-on-surface: var(--primary-yellow);
  --kendo-color-primary: var(--primary-yellow);
  --kendo-border-radius-none: 0px;
  --kendo-border-radius-md: var(--kendo-border-radius-none);
}

// Color system
$primary-green: #168736 !default;
$light-green: #34c768 !default;
$primary-red: #b9251c !default;
$light-red: #ff4236 !default;
$dark-grey: #2e2e38 !default;
$light-grey: #747480 !default;
$lighter-grey: #dadada !default;
$blue: #1777cf !default;
$primary-yellow: #ffe600 !default;
$primary-orange: #ed6500 !default;
$primary-black: #1a1a24 !default; // TODO: variable defined in styles.scss with different value
$text-black: #282836 !default;
$tab-label-gray: #23232f !default;

$background-color: #2e2e38;
$hr-divider-color: #e1e1e6;
$search-font-color: #2e2e38;
$search-active-background-color: #d9d9d9;
$header-font-color: white;
$saveButton-bg-color: #6b6f6e;
$footer-copyright-text-color: #747480;
$footer-copyright-privacy-text-color: #c4c4cd;
$modal-header-background-color: #282836;
$input-background-color: #f6f6fa;
$primary-white: #ffffff;
$primary-green: #168736;
$primary-red: #b9251c;
$medium-grey: #eaeaf2;
$dark-grey: #2e2e3c;
$blue: #155cb4;
$orange: #eb4f00;
$red: #b9251c;
$primary-black: #23232f; // TODO: variable defined in variables.scss with different value
$tab-header-border: #0000001f;
$border-color: #e0e0e0;
$select-search-no-found: #00000061;

// Fonts
$h2-font-size: 1.714rem; // Heading 2 font size.
$h6-font-size: 1rem; // Heading 6 font size.
$overline-font-size: 0.857rem; // Overline font size.

// Toasts
$toast-success: $primary-green !default;
$toast-error: $primary-red !default;
$toast-grey: $dark-grey !default;

// Spinner
$color-spinner-grey: #2e2e2e !default;

// Icons
$icon-Deliverable: cornflowerblue;
$icon-IR: darkblue;
