html {
  font-size: 14px !important;
  height: 100%;
}

body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  font-family: $ey-font-family;
  font-size: 1rem;

  .cdk-global-overlay-wrapper,
  .cdk-overlay-container {
    z-index: 9999 !important;
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

:host ::ng-deep {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    @include font-properties(0.75rem, $background-color, $ey-font-family, 300); /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    @include font-properties(0.75rem, $background-color, $ey-font-family, 300);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    @include font-properties(0.75rem, $background-color, $ey-font-family, 300);
  }
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

.input-grid {
  margin-top: 1.2rem !important;
}

.wrapper-div {
  width: 100%;
}

.sort-wrapper {
  > span {
    float: right;
    cursor: pointer;
  }
}
