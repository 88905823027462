.mat-mdc-form-field {
  position: relative;
  display: inline-block;
  text-align: left;

  & .mat-date-range-input-container {
    padding: 3.5px 0 10.5px 0;
  }

  &-infix {
    & label {
      max-width: calc(100% - 10px) !important;

      &.mdc-floating-label--float-above {
        max-width: calc(100% + 30px) !important;
      }
    }
  }

  & .mdc-text-field__input {
    padding-top: 3px;
  }

  & .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: 3px !important;
  }

  &.mat-form-field-appearance-fill {
    .mat-mdc-form-field-flex {
      border-radius: 0;
      background-color: var(--color-primary-100);
      height: var(--mat-mdc-form-field-flex-height);
    }

    .mat-mdc-form-field-label {
      color: var(--color-primary);
    }

    &.dcms-mat-select--no-label {
      .mat-mdc-form-field-infix {
        border-top: 4px solid transparent;
      }
    }

    &.mat-form-field-disabled {
      .mat-mdc-form-field-label {
        color: rgba(0, 0, 0, 0.38);
      }
      .mat-mdc-select-arrow {
        filter: contrast(0);
      }
    }
  }

  & .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mdc-floating-label--float-above {
    margin-top: 6px !important;
    margin-left: 0em;
  }

  &.mat-form-field-invalid .mdc-text-field--invalid .mat-mdc-form-field-required-marker.mdc-floating-label--required {
    color: var(--mat-form-field-error-text-color);
    margin-left: 2px;
  }

  & .mdc-floating-label {
    color: inherit !important;

    &--required::after {
      margin-left: 4px !important;
    }
  }

  & .mat-mdc-text-field-wrapper {
    background-color: var(--mdc-filled-text-field-container-color);
    flex: auto;
    will-change: auto;
    @include display-flex(row, center, flex-start);

    & .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 20px !important;
      margin-left: 0;
      margin-top: 0.6vh;
    }
  }

  & .mat-mdc-form-field-error-wrapper {
    padding: 0 10.5px !important;

    & .mat-mdc-form-field-error {
      display: flex !important;
      margin-top: 4px !important;
    }
  }

  & .mdc-text-field {
    padding: 0 10.5px !important;
    align-items: center !important;

    &--disabled {
      color: var(--mat-select-disabled-trigger-text-color);

      & .mat-mdc-input-element {
        pointer-events: none !important;
        cursor: none;
      }
    }

    &--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
      border-bottom-width: 2px;
    }
  }

  & .mat-mdc-form-field-textarea-control.mat-mdc-input-element {
    height: auto !important;
    margin-top: 3px;
  }
}

.mat-form-field-disabled {
  & .mdc-line-ripple--deactivating::before {
    border: none !important;
  }
}

.mdc-list-item__primary-text {
  @include display-flex(row, center, flex-start);
  box-sizing: border-box;
  position: relative;
  height: inherit;
  color: inherit;

  & .ng-star-inserted {
    @include display-flex(row, center, flex-start);
  }
}

.mat-form-field-label {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  --mat-form-field-subscript-text-line-height: auto;
}

:host ::ng-deep .mdc-line-ripple {
  height: 0em;
  background-image: none !important;
  background-color: $footer-copyright-text-color !important;
}

.mat-mdc-form-field:has(textarea) {
  .mat-mdc-text-field-wrapper {
    max-height: 300px !important;

    & textarea {
      resize: none;
    }
  }
}
