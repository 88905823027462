.three-dots {
  color: $lighter-grey;
  cursor: pointer;
}

.three-dots:hover {
  color: $light-grey;
}

.view-heading-container {
  padding: 0.4rem 0.4rem 0.4rem 0;
  display: flex;
  flex-direction: column;

  span:nth-of-type(1) {
    line-height: 1.5714rem;
    padding: 0.1428rem;
    @include font-properties(1rem, $background-color, $ey-font-family, 400);
  }

  span:nth-of-type(2) {
    line-height: 1.1428rem;
    padding: 0.1428rem;
    @include font-properties(0.8571rem, $background-color, $ey-font-family, 300);
  }
}

.view-edit-action-div {
  width: 8em;
  background-color: $primary-white;
  display: none;
  position: absolute;
  z-index: 9999;
  margin-left: 0.4em;
  border: 0.1rem solid $footer-copyright-privacy-text-color;
}

.view-sort-action-div {
  width: 8.125em;
  background-color: $primary-white;
  display: none;
  position: absolute;
  z-index: 9999;
  margin-left: 4.5em;
  border: 0.1rem solid $footer-copyright-privacy-text-color;
  margin-top: 0.5em;

  div:nth-of-type(1) {
    padding-left: 0em !important;
  }

  div:nth-of-type(2) {
    padding-left: 0em !important;
  }

  div:nth-of-type(3) {
    padding-left: 0em !important;
  }

  div:nth-of-type(4) {
    padding-left: 0em !important;
  }
}

.action-text-container {
  cursor: pointer;
  margin-left: 1.2em;
  @include font-properties(1rem, $background-color, $ey-font-family, 300);
  margin-top: 1vh;
  height: 4.2vh;

  i {
    float: left;
    margin-right: 1em;
  }
}

.float-left {
  float: left;
}
