app-search-item-list {
  & .common-item-list__subline .mat-icon {
    @include icon-size(15px);
  }

  & .search-list-item__container {
    width: calc(712px - 10.5px - 28px);
  }

  & .common-item-list__item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 20px;

    & p {
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      white-space: nowrap !important;
      width: 100%;
    }
  }

  & .selection-item-list__item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    & p {
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      white-space: nowrap !important;
      width: 100%;
    }
  }

  & p {
    text-align: left !important;
  }
}
