.mat-mdc-list-base .mat-mdc-list-item.custom-fields-creator__modules-list-item {
  border: none !important;
  cursor: pointer;

  & .mdc-list-item__primary-text {
    position: absolute;
    top: 0;
    font-size: 1rem !important;
  }
  &--active {
    & .mdc-list-item__primary-text {
      font-weight: 600 !important;
    }
  }

  & .mdc-list-item__content {
    padding: var(--spacing-small) var(--spacing-big) !important;
  }
}

.custom-fields-creator__modules-list .mdc-list-item {
  padding: 0 !important;
}

.custom-fields-creator__tabs .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--mat-tab-header-inactive-label-text-color) !important;
}

.custom-fields-creator__modules-title {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.page-title__back-button .mat-icon {
  font-size: 15px;
  height: 15px;
  width: 15px;
}

.overview-container {
  margin-top: 2em;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  background-color: $primary-white;
  overflow: hidden;
  @include display-flex(column, flex-start, flex-start);
}

.overview-grid {
  @include display-flex(row, flex-start, space-between);
  overflow: hidden;
  margin-left: 20px;
  margin-right: 0;
  width: calc(100% - 20px);
  gap: 20px;
}
