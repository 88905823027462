.modal-content {
  & .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-flex {
    height: auto !important;
  }

  & .mat-mdc-select-arrow {
    margin-right: -4px;
  }

  & .mat-icon {
    @include icon-size(21px);
  }

  & .mdc-list-item {
    background-color: $primary-white;
    margin: 0 16px 8px;
    position: relative;
    height: inherit;
  }

  & .mat-mdc-list-option {
    padding: 0 16px !important;
  }

  & .mat-mdc-list-item {
    height: auto !important;
    min-height: 48px !important;
    width: calc(100% - 32px) !important;
  }

  & .mdc-list-item__start {
    margin-left: 0 !important;
  }

  & .mat-mdc-list-option .mdc-checkbox__background {
    width: 16px !important;
    height: 16px !important;
  }

  & .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    margin: 16px !important;
  }

  & .modal-footer {
    display: flex;
    gap: 15px;

    & .mdc-button {
      margin: 0;
    }
  }

  .mat-mdc-list-item:focus,
  .mat-mdc-list-item:active,
  .mat-mdc-list-item:hover {
    background-color: $primary-white !important;
    opacity: 1 !important;
  }

  & .mat-mdc-slide-toggle {
    margin-bottom: 10px;
  }
}
