.search-list-item__container {
  & .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  & .mat-mdc-text-field-wrapper {
    background-color: $primary-white !important;
  }
}

.mat-select-search-inside-mat-option .mat-select-search-clear {
  margin-right: 10.5px;
}

.mat-select-search-clear {
  right: 0 !important;
  height: 42px !important;
  top: 0 !important;

  & .mat-icon {
    margin-right: 0 !important;
  }
}

.mat-select-search-inner {
  border-width: 0 !important;

  & .mat-mdc-icon-button.mat-mdc-button-base {
    width: var(--mat-mdc-icon-button-width) !important;
  }
}

.mat-mdc-option.contains-mat-select-search {
  padding-right: 0 !important;
  border-bottom: 1px solid $border-color !important;
  background-color: $primary-white !important;
  position: sticky;
  top: 0;
  z-index: 1000;
  cursor: pointer !important;
  pointer-events: all !important;
  opacity: 1;

  & .mat-select-search-input {
    padding-left: 0;
  }
}

.mat-mdc-option.mdc-list-item.mat-select-search-no-entries-found {
  padding: 0;
}

.mat-select-search-no-entries-found .mdc-list-item__primary-text {
  & .mat-select-search-input {
    border-bottom: 1px solid $border-color;
    padding-left: 16px;
  }

  & .mat-select-search-no-entries-found {
    padding: 0 0 0 16px !important;
    color: $select-search-no-found !important;
  }
}

.search-input {
  height: 2.75rem;
  background-color: $input-background-color;
  border-radius: 0;
  border: none;
  border-bottom: 0.163rem solid $lighter-grey;
  @include font-properties(0.875rem, $search-font-color, $ey-font-family, 300);
}

.mat-mdc-input-element {
  text-overflow: ellipsis;
  border: none;
  background: $input-background-color;
  @include font-properties(14px, $background-color, $ey-font-family, 400);
  height: 1.875em !important;
}
