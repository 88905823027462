.index-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2e2e2e;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  top: 48%;
  left: 48%;
  animation: spin 0.7s infinite linear;
}

.index-spinner-overlay {
  z-index: 1;
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
